/* eslint-disable react/jsx-no-bind */
import { Col, Pagination, Row } from 'antd';
import React, { useEffect } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import DataTable from '../../../components/table/DataTable';
import { DataService } from '../../../config/dataService/dataService';
import ModalDeposit from '../../deposit/ModalDeposit';
import { BorderLessHeading, Main } from '../../styled';
import { dataTableColumn } from './config';

function TransactionDepositPage() {
  const [state, setState] = React.useState({
    data: [],
    loading: false,
    total: 0,
    currentPage: 1,
    search: null,
  });

  const PageRoutes = [
    {
      path: '/',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '#',
      breadcrumbName: 'Transaction',
    },
    {
      path: '/deposit/history',
      breadcrumbName: 'Deposit',
    },
  ];

  const loadData = (url = '/transaction/deposit', perpage = 10, params = { page: state.currentPage }) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(url, { perpage, ...params })
      .then((res) => {
        const { data, total, current_page } = res.data.data;
        setState((currentState) => ({
          ...currentState,
          loading: false,
          data,
          total,
          currentPage: current_page,
        }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onChange = (page, pageSize = 10) => {
    loadData(`/transaction/deposit/?page=${page}`, pageSize, {
      search: state.search,
      page,
    });
  };

  const { data, currentPage, total, search, loading } = state;
  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Top Up History" routes={PageRoutes} />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <BorderLessHeading>
              <Cards title="List Transaction Deposit">
                <DataTable
                  filterOption
                  loading={loading}
                  tableData={data}
                  filterOnchange={(e) => setState((currentState) => ({ ...currentState, search: e }))}
                  onSearch={(e) => {
                    loadData(`/transaction/deposit`, 10, {
                      search: e || search,
                    });
                  }}
                  refetch={() => loadData()}
                  columns={[...dataTableColumn]}
                />
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  total={total}
                  className="mt-4 text-center"
                  onChange={onChange}
                  pageSizeOptions={['10', '20', '50', '100', '200', '500']}
                />
              </Cards>
            </BorderLessHeading>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default TransactionDepositPage;

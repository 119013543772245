/* eslint-disable react/jsx-no-bind */
import UilEllipsisH from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { Col, Pagination, Row, Switch } from 'antd';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../../components/atoms/ConfirmModal';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Dropdown } from '../../../components/dropdown/dropdown';
import { PageHeader } from '../../../components/page-headers/page-headers';
import DataTable from '../../../components/table/DataTable';
import { DataService } from '../../../config/dataService/dataService';
import { updateStatus } from '../../../utility/utility';
import { BorderLessHeading, Main } from '../../styled';
import ModalForm from './components/ModalForm';
import { dataTableColumn } from './config';

function BannerPage() {
  const [state, setState] = React.useState({
    data: [],
    loading: false,
    total: 0,
    currentPage: 1,
    search: null,
    roles: [],
  });

  const PageRoutes = [
    {
      path: '/',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '#',
      breadcrumbName: 'Master',
    },
    {
      path: '/master/banner',
      breadcrumbName: 'Banner',
    },
  ];

  const loadData = (url = '/master/banner/list', perpage = 10, params = { page: state.currentPage }) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(url, { perpage, ...params })
      .then((res) => {
        const { data, total, current_page } = res.data.data;
        setState((currentState) => ({
          ...currentState,
          loading: false,
          data,
          total,
          currentPage: current_page,
        }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  const loadRoles = () => {
    DataService.get('/setting/role').then((res) => {
      const { data } = res.data;
      setState((currentState) => ({ ...currentState, roles: data }));
    });
  };

  useEffect(() => {
    loadData();
    loadRoles();
  }, []);

  const onChange = (page, pageSize = 10) => {
    loadData(`/master/banner/list?page=${page}`, pageSize, {
      search: state.search,
      page,
    });
  };

  const { data, currentPage, total, roles, search, loading } = state;
  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Table" routes={PageRoutes} />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <BorderLessHeading>
              <Cards
                title="List Level Price"
                isbutton={<ModalForm url={`/master/banner`} method="POST" roles={roles} refetch={() => loadData()} />}
              >
                <DataTable
                  filterOption
                  loading={loading}
                  tableData={data}
                  filterOnchange={(e) => setState((currentState) => ({ ...currentState, search: e }))}
                  onSearch={(e) => {
                    loadData(`/master/banner/list`, 10, {
                      search: e || search,
                    });
                  }}
                  refetch={() => loadData()}
                  columns={[
                    ...dataTableColumn,
                    {
                      title: 'Status',
                      dataIndex: 'checked',
                      key: 'checked',
                      render: (text, record, index) => {
                        return (
                          <Switch
                            value={record.status > 0 ? 0 : 1}
                            checked={record.status > 0}
                            onChange={(val) => {
                              updateStatus({
                                url: `/master/banner/status/${record.id}`,
                                data: { status: val ? 1 : 0 },
                              });

                              const newData = [...data];
                              newData[index].status = val ? 1 : 0;
                              setState((currentState) => ({ ...currentState, data: newData }));
                            }}
                          />
                        );
                      },
                    },
                    {
                      title: 'Actions',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      render: (text, record, index) => {
                        return (
                          <div>
                            <Dropdown
                              content={
                                <>
                                  <ModalForm
                                    update
                                    initialValues={{ ...record, _method: 'PUT' }}
                                    url={`/master/banner/${record.id}`}
                                    refetch={() => loadData()}
                                    roles={roles}
                                  />
                                  <ConfirmModal url={`/master/banner/${record.id}`} refetch={() => loadData()}>
                                    <Link to="#">
                                      <UilTrashAlt />
                                      Delete
                                    </Link>
                                  </ConfirmModal>
                                </>
                              }
                            >
                              <Link to="#">
                                <UilEllipsisH />
                              </Link>
                            </Dropdown>
                          </div>
                        );
                      },
                    },
                  ]}
                />
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  total={total}
                  className="mt-4 text-center"
                  onChange={onChange}
                  pageSizeOptions={['10', '20', '50', '100', '200', '500']}
                />
              </Cards>
            </BorderLessHeading>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default BannerPage;

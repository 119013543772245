const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Title',
    dataIndex: 'banner_title',
    key: 'banner_title',
  },
  {
    title: 'Image',
    dataIndex: 'banner_image_url',
    key: 'banner_image_url',
    render: (text, record, index) => {
      return (
        <div>
          <img src={record.banner_image_url} alt="" style={{ height: 50 }} />
        </div>
      );
    },
  },
];

export { dataTableColumn };

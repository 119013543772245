import React from 'react';

const ProductItemDetail = ({ label = 'Label', price = 0, selected = false, onClick }) => {
  if (selected) {
    return (
      <div className="w-1/2 p-2">
        <div className=" border border-blue-700 p-3 rounded-lg">
          <p>{label}</p>
          <p className="text-right text-lg font-bold mt-2">{price}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="w-1/2 p-2 cursor-pointer" onClick={onClick}>
      <div className=" border p-3 rounded-lg">
        <p>{label}</p>
        <p className="text-right text-lg font-bold mt-2">{price}</p>
      </div>
    </div>
  );
};

export default ProductItemDetail;

import { LoadingOutlined } from '@ant-design/icons';
import { message, Modal, Result } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getItem } from '../../../../../utility/localStorageControl';
import Button from '../../../Components/Button';
import PaymentMethodItem from '../../../Components/PaymentMethodItem';
import { API_URL } from '../../../Utils/Constant';
import { formatNumber } from '../../../Utils/helper';
import { DataService } from '../../../../../config/dataService/dataService';
import { toast } from 'react-toastify';
import { generatePasswordHash } from '../../../../../utility/utility';

const TopUpModalPayment = ({ amount = 0, disabled = false, form }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [ref, setRef] = useState();

  const showModal = () => {
    if (!getItem('access_token')) {
      toast.error(`Silahkan Login terlebih dahulu`);
      return navigate('/auth/login');
    }

    if (amount < 10000) {
      toast.error(`Minimal deposit Rp 10.000`);
      return;
    }

    loadPaymentMethod();
  };

  const loadPaymentMethod = () => {
    setLoadingPaymentMethods(true);
    axios
      .post(
        `${API_URL}/public/payment-list`,
        {
          deposit_amount: amount,
        },
        {
          headers: {
            'Punyakios-Api-Key': generatePasswordHash(),
          },
        },
      )
      .then((res) => {
        const { payment_methods } = res.data.data;
        setIsModalOpen(true);
        setPaymentMethods(payment_methods);
        setLoadingPaymentMethods(false);
      })
      .catch((err) => {
        setLoadingPaymentMethods(false);
      });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createDeposit = () => {
    const url = '/user/deposit/create';
    setLoadingSubmit(true);
    DataService.post(url, {
      deposit_amount: amount,
      payment: selectedPayment,
    })
      .then((res) => {
        const { reference } = res.data.data;
        message.success(res.data.message);
        setLoadingSubmit(false);
        setIsModalOpen(false);
        setModalSuccess(true);
        setRef(reference);
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          setLoadingSubmit(false);
          message.error(data?.message);
        }
      });
  };

  return (
    <div>
      <Button
        onClick={showModal}
        title={'Pilih Metode Pembayaran'}
        disabled={disabled}
        loading={loadingPaymentMethods}
      />

      <Modal title={`Rincian Transaksi`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <div className={'mt-2 border-t py-4'}>
          <table width={'100%'}>
            <tr>
              <td width={'50%'} className="font-bold">
                Nominal Deposit
              </td>
              <td width={'50%'}>: {formatNumber(amount, 'Rp')}</td>
            </tr>
            <tr>
              <td width={'50%'} className="font-bold">
                Biaya Admin
              </td>
              <td width={'50%'}>: {formatNumber(selectedPayment?.totalFee, 'Rp')}</td>
            </tr>
            <tr>
              <td width={'50%'} className="font-bold">
                Total Bayar
              </td>
              <td width={'50%'}>: {formatNumber(parseInt(selectedPayment?.totalFee) + parseInt(amount), 'Rp')}</td>
            </tr>
          </table>

          <div className="mt-4 border-t pt-4">
            <div>
              <div>
                <h4 className="font-medium">Transfer Bank (Otomatis)</h4>
                {paymentMethods?.map((item, index) => (
                  <PaymentMethodItem
                    key={index}
                    image={item.paymentImage}
                    title={item.paymentName}
                    selected={selectedPayment?.paymentMethod === item.paymentMethod}
                    onClick={() => {
                      setSelectedPayment(item);
                    }}
                  />
                ))}
              </div>

              {loadingPaymentMethods && (
                <div className="w-full mx-auto text-center my-4">
                  <LoadingOutlined />
                  <span className="ml-2">Loading Metode Pembayaran</span>
                </div>
              )}
            </div>
          </div>
          <Button
            onClick={() => createDeposit()}
            title={'Bayar Sekarang'}
            disabled={!selectedPayment}
            loading={loadingSubmit}
          />
        </div>
      </Modal>

      {/* success transaction */}
      <Modal
        title={`Deposit Berhasil Dibuat`}
        open={modalSuccess}
        onOk={() => {
          setModalSuccess(false);
          navigate(`/topup/detail/${ref}`);
        }}
        onCancel={() => {
          setModalSuccess(false);
          navigate(`/topup/detail/${ref}`);
        }}
        footer={null}
      >
        <Result
          status="success"
          title="Deposit Berhasil Dibuat"
          subTitle="Silahkan cek detail Deposit di menu riwayat Deposit"
          extra={[
            <Button
              type="primary"
              key="console"
              title="Lihat Deposit"
              onClick={() => {
                navigate(`/topup/detail/${ref}`);
              }}
            />,
          ]}
        />
      </Modal>
    </div>
  );
};

export default TopUpModalPayment;

import actions from './actions';

const {
  LOAD_PRODUCT_CATEGORY_BEGIN,
  LOAD_PRODUCT_CATEGORY_SUCCESS,
  LOAD_PRODUCT_CATEGORY_ERR,
  LOAD_PRODUCT_PROVIDER_BEGIN,
  LOAD_PRODUCT_PROVIDER_SUCCESS,
  LOAD_PRODUCT_PROVIDER_ERR,
} = actions;

const initialStateFilter = {
  categories: [],
  providers: [],
  loadingCategory: false,
  loadingProvider: false,
  error: null,
};

const productReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOAD_PRODUCT_CATEGORY_BEGIN:
      return {
        loadingCategory: true,
      };
    case LOAD_PRODUCT_CATEGORY_SUCCESS:
      return {
        categories: data,
        loadingCategory: false,
      };
    case LOAD_PRODUCT_CATEGORY_ERR:
      return {
        error: err,
        loadingCategory: false,
      };
    case LOAD_PRODUCT_PROVIDER_BEGIN:
      return {
        loadingProvider: true,
      };
    case LOAD_PRODUCT_PROVIDER_SUCCESS:
      return {
        providers: data,
        loadingProvider: false,
      };
    case LOAD_PRODUCT_PROVIDER_ERR:
      return {
        error: err,
        loadingProvider: false,
      };
    default:
      return state;
  }
};

export default productReducer;

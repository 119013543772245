import { Col, Row, Skeleton } from 'antd';
import React, { lazy, Suspense } from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { SettingWrapper } from './overview/Style';

const Activity = lazy(() => import('./overview/Activity'));

function Notifications() {
  const PageRoutes = [
    {
      path: '/admin',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '',
      breadcrumbName: 'Notification',
    },
  ];
  const path = '.';
  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Notification" routes={PageRoutes} />
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <SettingWrapper>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active paragraph={{ rows: 10 }} />
                  </Cards>
                }
              >
                <Activity />
              </Suspense>
            </SettingWrapper>
          </Col>
        </Row>
      </Main>
    </>
  );
}

Notifications.propTypes = {
  // match: propTypes.object,
};

export default Notifications;

const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Telepon',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
  },
  {
    title: 'Level',
    dataIndex: 'role',
    key: 'role',
    render: (text, record, index) => {
      return record?.role?.role_name || '-';
    },
  },
];

export { dataTableColumn };

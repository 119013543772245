/* eslint-disable react/jsx-no-bind */
import { Col, Result, Row, Tag, message } from 'antd';
import React, { useEffect } from 'react';
import Pusher from 'pusher-js';
import Countdown from 'react-countdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { H1, H5 } from '../../components/heading/style';
import { DataService } from '../../config/dataService/dataService';
import { BorderLessHeading, Main } from '../styled';
import { getUserProfile } from '../../redux/user/actionCreator';
import { useDispatch } from 'react-redux';
import { ArrowLeftOutlined, CopyOutlined, LeftCircleFilled } from '@ant-design/icons';
import Heading from '../../components/heading/heading';
import { formatNumber } from '../../utility/utility';

function DepositPage({ type = 'deposit' }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reference } = useParams();
  const [pusherChannel, setPusherChannel] = React.useState(null);
  const [state, setState] = React.useState({
    payments: false,
    amount_deposit: 0,
    data: null,
  });

  const getDetail = () => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.get(`/transaction/${type}/detail/${reference}`)
      .then((res) => {
        const { data } = res.data;
        setState((currentState) => ({ ...currentState, loading: false, data }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  const updateStatus = (status) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(`/transaction/${type}/status/${reference}`, { status })
      .then((res) => {
        const { data } = res.data;
        setState((currentState) => ({ ...currentState, loading: false, data: { ...currentState.data, status } }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    getDetail();

    // Pusher.logToConsole = true;
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    const channelPusher = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);
    setPusherChannel(channelPusher);
  }, []);

  useEffect(() => {
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind('payment-success');
      pusherChannel.bind('payment-success', function (data) {
        // get percentage from two data
        dispatch(getUserProfile());
        if (data?.redirect) {
          message.success('Pembayaran Berhasil');
          setTimeout(function () {
            window.location.href = data?.redirect;
          }, 1000);
        } else {
          window.location.reload();
        }
      });
    }
  }, [pusherChannel]);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Result
          status="error"
          title="Payment Failed"
          subTitle="Transaksi Telah Dibatalkan Otomatis Karna Pembayaran Tidak Ditemukan."
          extra={[
            <Link to={`/admin/deposit/history`}>
              <Button type={'primary'} key="buy">
                Kembali Ke Dashboard
              </Button>
            </Link>,
          ]}
        />
      );
    } else {
      // Render a countdown

      return (
        <div>
          <span className="text-lg font-bold">{hours > 9 ? hours : `0${hours}`}</span>
          <span className="text-lg font-bold">:{minutes > 9 ? minutes : `0${minutes}`}:</span>
          <span className="text-lg font-bold">{seconds > 9 ? seconds : `0${seconds}`}</span>
        </div>
      );
    }
  };

  const { data } = state;
  return (
    <>
      {/* <PageHeader className="ninjadash-page-header-main" title="Table" routes={PageRoutes} /> */}
      <Main style={{ marginTop: 40 }}>
        <Row gutter={15}>
          <Col xs={12} offset={6}>
            <BorderLessHeading>
              <div
                className="flex flex-row items-center bg-white p-4 mb-2 rounded-lg cursor-pointer"
                onClick={() => navigate('/admin/deposit/history')}
              >
                <ArrowLeftOutlined />
                <Heading as={'h5'} className={'ml-4'}>
                  Konfirmasi Pembayaran
                </Heading>
              </div>
              <Cards>
                <div>
                  {data?.status < 1 && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 30,
                      }}
                    >
                      <div style={{ textAlign: 'left', lineHeight: 0.5 }}>
                        <H5 className="mb-2">Selesaikan Sebelum</H5>
                        <p>{data?.created_payment}</p>
                      </div>
                      <div>
                        {data && (
                          <Countdown
                            date={Date.now() + data?.expired_payment}
                            renderer={renderer}
                            onComplete={() => {
                              if (!data?.payment) {
                                updateStatus(3);
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <div>
                    <ProductItem
                      label="Status"
                      value={
                        <Tag color={data?.status_color} style={{ color: '#fff' }}>
                          {data?.status_name}
                        </Tag>
                      }
                    />
                    <ProductItem label="ID Transaksi" value={data?.trx_id} />
                    <ProductItem label="Metode Pembayaran" value={data?.payment_name} />
                    {!data?.payment && (
                      <>
                        <ProductItem label="Nominal Deposit" value={formatNumber(data?.deposit_amount, 'Rp ')} />
                        <ProductItem label="Biaya Admin" value={formatNumber(data?.payment_fee, 'Rp ')} />
                        <ProductItem
                          label={'Nominal Transfer'}
                          value={formatNumber(parseInt(data?.deposit_amount) + parseInt(data?.payment_fee), 'Rp ')}
                        />
                      </>
                    )}

                    {data?.payment && (
                      <>
                        <ProductItem
                          label="Nominal Deposit"
                          value={formatNumber(data?.deposit_amount - data?.unique_code, 'Rp ')}
                        />
                        <ProductItem
                          label={'Jumlah Transfer'}
                          value={data?.deposit_amount}
                          originalValue={data?.deposit_amount}
                          copyText
                        />
                        <ProductItem label="Berita Transfer" value={data?.ref} copyText />
                        <ProductItem
                          label="Nomor Rekening Transfer"
                          value={data?.payment?.bank_account_number}
                          copyText
                        />
                        <ProductItem label="Nama Rekening Transfer" value={data?.payment?.bank_account_name} />
                      </>
                    )}
                  </div>

                  {data?.payment && (
                    <div style={{ marginTop: 20 }}>
                      <p>
                        Mohon sesuaikan transfer sesuai dengan berita dan jumlah yang tertera (tapa pembulatan atau
                        tekan "copy") untuk memudahkan verifikasi transfer deposit.
                      </p>
                    </div>
                  )}
                  {!data?.payment && data?.status < 1 && (
                    <div className="w-full mt-4">
                      <div
                        className="flex flex-row items-center justify-center bg-[#8e44ad] p-2 mb-2 rounded-lg cursor-pointer border"
                        onClick={() => window.open(data?.paymentUrl, '_blank')}
                        style={{ backgroundColor: '#8e44ad' }}
                      >
                        <Heading as={'h5'} className={'ml-4'}>
                          <span className=" text-white ">Bayar Sekarang</span>
                        </Heading>
                      </div>
                    </div>
                  )}
                </div>
              </Cards>
            </BorderLessHeading>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export const ProductItem = ({
  classNameValue,
  classNameLabel,
  label = 'Label',
  value = 'Value',
  originalValue,
  copyText = false,
}) => {
  return (
    <div className="flex flex-row justify-between items-center mb-2">
      <p className={classNameLabel}>
        <strong>{label}</strong>
      </p>
      {copyText ? (
        <p className={`flex flex-row items-center ${classNameValue}`}>
          <span className="mt-2">{value}</span>
          <span>
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(originalValue || value);
                message.success('Text copied to clipboard');
              }}
            />
          </span>
        </p>
      ) : (
        <p className={classNameValue}>{value}</p>
      )}
    </div>
  );
};

export default DepositPage;

const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Kategori',
    dataIndex: 'category_name',
    key: 'category_name',
  },
  {
    title: 'Logo',
    dataIndex: 'category_image_url',
    key: 'category_image_url',
    render: (text, record, index) => {
      return (
        <div>
          <img src={record.category_image_url} alt="" style={{ height: 50 }} />
        </div>
      );
    },
  },
];

export { dataTableColumn };

import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { ChangePasswordWrapper } from './style';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { BasicFormWrapper } from '../../../styled';
import Heading from '../../../../components/heading/heading';
import { DataService } from '../../../../config/dataService/dataService';

function Password() {
  const [form] = Form.useForm();
  const [state, setState] = useState({
    loading: false,
  });

  const handleSubmit = (values) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(`/user/update/password`, values)
      .then((res) => {
        setState((currentState) => ({ ...currentState, loading: false }));
        message.success('Password Updated Successfully');
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
        const data = err.response.data;

        if (data.message) {
          return form.setFields([
            {
              name: 'old_password',
              errors: [data.message],
            },
          ]);
        }

        const errors = [];
        for (const key in data.messages) {
          errors.push({
            name: key,
            errors: data.messages[key],
          });
        }

        form.setFields(errors);
      });
  };

  return (
    <ChangePasswordWrapper>
      <Cards
        title={
          <div className="setting-card-title">
            <Heading as="h4">Password Settings</Heading>
            <span>Change or reset your account password</span>
          </div>
        }
      >
        <Row justify="center">
          <Col xs={24}>
            <BasicFormWrapper>
              <Form form={form} name="changePassword" onFinish={handleSubmit}>
                <Form.Item
                  name="old_password"
                  label="Old Password"
                  rules={[
                    {
                      required: true,
                      message: 'Input old password',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: 'Input new password',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  label="New Password Confirmation"
                  rules={[
                    {
                      required: true,
                      message: 'Input new password confirmation',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <div className="setting-form-actions">
                  <Button
                    size="default"
                    type="primary"
                    onClick={() => {
                      if (state.loading) {
                        return false;
                      }

                      return form.submit();
                    }}
                  >
                    {state.loading ? 'Loading...' : ' Update Password'}
                  </Button>
                </div>
              </Form>
            </BasicFormWrapper>
          </Col>
        </Row>
      </Cards>
    </ChangePasswordWrapper>
  );
}

export default Password;

import UilInbox from '@iconscout/react-unicons/icons/uil-inbox';
import React, { useEffect } from 'react';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { ActivityContents } from './Style';
import { DataService } from '../../../../config/dataService/dataService';
import { Link } from 'react-router-dom';
import { ReactComponent as NoNotification } from '../../../client/assets/ilustration/no-notification.svg';

function ActivityContent() {
  const [notifications, setNotifications] = React.useState([]);

  const loadNotification = () => {
    DataService.get('/notification/list').then((res) => {
      const { data } = res.data;
      setNotifications(data);
    });
  };

  useEffect(() => {
    loadNotification();
  }, []);

  const markAsRead = (id) => {
    DataService.get(`/notification/update/${id}`).then((res) => {
      const { data } = res.data;
      loadNotification();
    });
  };

  return (
    <ActivityContents>
      <Cards headless style={{ backgroundColor: '#f5f6fa' }}>
        {notifications.length < 1 ? (
          <div>
            <div className="flex justify-center items-center">
              <NoNotification />
            </div>
            <div className="flex justify-center items-center">
              <span className="text-gray-500">Tidak ada notifikasi</span>
            </div>
          </div>
        ) : (
          <ul className="activity-list">
            {notifications.map((notification) => (
              <li
                className="activity-list__single cursor-pointer"
                key={notification.id}
                style={{ backgroundColor: notification?.status === 'read' ? '#fff' : '#f5f6fa' }}
                onClick={() => {
                  if (notification?.status === 'unread') {
                    markAsRead(notification.id);
                  }
                }}
              >
                {/* <span className="activity-icon primary w-8 h-8">
                <UilInbox />
              </span> */}
                <div className="activity-content" onClick={() => {}}>
                  <div className="activity-info w-full">
                    <div className="w-full">
                      <div className="flex justify-between items-center">
                        <span className="inline-text font-bold">{notification?.title}</span>
                        <span className="text-xs text-black">{notification?.created_at}</span>
                      </div>
                      <span className="hour">{notification?.description}</span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Cards>
    </ActivityContents>
  );
}

export default ActivityContent;

import React from 'react';
import ProductItem from '../ProductItem';
import { useNavigate } from 'react-router-dom';

const Product = ({ data, loading, detail = false, homePage = false }) => {
  const navigate = useNavigate();
  if (detail) {
    return (
      <div className="mx-2 bg-white pt-2 rounded-xl ">
        <div className="flex justify-start items-start flex-wrap">
          {data.map((item, index) => {
            return (
              <ProductItem
                key={item.id}
                onClick={() => {
                  navigate('/product/' + item.category_slug);
                }}
                label={item.category_name}
                img={item.category_image_url}
                homePage={homePage}
              />
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className="mx-2 bg-white pt-2 rounded-xl ">
      <div className="flex justify-between items-center">
        {data.map((item, index) => {
          if (index < 3) {
            return (
              <ProductItem
                key={item.id}
                onClick={() => {
                  navigate('/product/' + item.category_slug);
                }}
                label={item.category_name}
                img={item.category_image_url}
                homePage={homePage}
              />
            );
          }
        })}
        <ProductItem
          onClick={() => {
            navigate('/product/lainnya');
          }}
          label={'Lainnya'}
          img={'https://img.icons8.com/cute-clipart/64/null/connection-status-off.png'}
          homePage={homePage}
        />
      </div>
    </div>
  );
};

export default Product;

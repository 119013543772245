import { message, Modal, Result } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../../../../../redux/user/actionCreator';
import { getItem } from '../../../../../utility/localStorageControl';
import Button from '../../../Components/Button';
import PaymentMethodItem from '../../../Components/PaymentMethodItem';
import { API_URL, DASHBOARD_URL } from '../../../Utils/Constant';
import { formatNumber } from '../../../Utils/helper';
import { toast } from 'react-toastify';
import { generatePasswordHash } from '../../../../../utility/utility';

const ModalDetailPembelian = ({
  product_type = 'pulsa',
  onSelectedPayment,
  selectedPayment = null,
  disabled = false,
  form,
  fieldSelected,
  selectedProduct,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalPin, setModalPin] = useState(false);
  const [inquiry, setInquiry] = useState({});
  const [loadingInquiry, setLoadingInquiry] = useState(false);
  const [modalInquiry, setModalInquiry] = useState(false);
  const [responseInquiry, setResponseinquiry] = useState(null);

  const user = getItem('user');
  const showModal = () => {
    if (!getItem('access_token')) {
      message.error(`Silahkan Login terlebih dahulu`);
      return navigate('/auth/login');
    }
    if (form.getFieldValue(fieldSelected?.name)) {
      return cekTagihan();
    }

    return message.error(`Silahkan isi ${fieldSelected?.label} terlebih dahulu`);
  };

  const cekTagihan = () => {
    if (fieldSelected?.priceType === 'admin_fee') {
      setLoadingInquiry(true);
      return axios
        .post(`${API_URL}/public/cek-tagihan`, {
          product_sku: selectedProduct?.product_sku,
          customer_no: form.getFieldValue(fieldSelected?.name),
        })
        .then((res) => {
          if (res.data.data) {
            setInquiry(res.data.data);
            setIsModalOpen(true);
          }
          return setLoadingInquiry(false);
          // loadPaymentMethodManual();
          // return loadPaymentMethod(res?.data?.data?.total || 0);
        })
        .catch((err) => {
          const res = err.response;
          setResponseinquiry(res.data);
          // setModalInquiry(true);
          setIsModalOpen(false);
          toast.error(res?.data?.message || 'Terjadi kesalahan, silahkan coba lagi');

          return setLoadingInquiry(false);
        });
    }
    if (selectedProduct?.product_brand === 'pln') {
      setLoadingInquiry(true);
      return axios
        .post(`${API_URL}/public/validasi-pelanggan`, {
          customer_no: form.getFieldValue(fieldSelected?.name),
        })
        .then((res) => {
          const { data } = res.data;
          setLoadingInquiry(false);
          if (data.name === '') {
            return toast.error('Nomor pelanggan tidak ditemukan');
          }
          setInquiry(data);
          setIsModalOpen(true);
        })
        .catch((err) => {
          const res = err.response;
          setLoadingInquiry(false);
          toast.error(res?.data?.message || 'Terjadi kesalahan, silahkan coba lagi');
        });
    }

    // loadPaymentMethodManual();
    // loadPaymentMethod(selectedProduct?.member_price || 0);
    return setIsModalOpen(true);
  };

  // const loadPaymentMethod = (amount) => {
  //   setLoadingPaymentMethods(true);
  //   axios
  //     .post(`${API_URL}/public/payment-list`, {
  //       deposit_amount: amount,
  //     })
  //     .then((res) => {
  //       const { payment_methods } = res.data.data;
  //       setPaymentMethods(payment_methods);
  //       setLoadingPaymentMethods(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoadingPaymentMethods(false);
  //     });
  // };

  // const loadPaymentMethodManual = () => {
  //   axios
  //     .get(`${API_URL}/master/payment-method`, {
  //       headers: {
  //         Authorization: `Bearer ${getItem('access_token')}`,
  //       },
  //     })
  //     .then((res) => {
  //       const { data } = res.data;
  //       setManualPaymentMethods(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfirmPayment = () => {
    // setLoadingPaymentMethods(true)
    axios
      .post(
        `${API_URL}/transaction/product/create`,
        {
          payment_method: selectedPayment?.paymentMethod ?? 'saldo',
          payment_method_id: selectedPayment?.id,
          product_id: selectedProduct.id,
          customer_no: form.getFieldValue(fieldSelected?.name),
          product_sku: selectedProduct.product_sku,
          price: selectedProduct?.product_type === 'pasca' ? inquiry?.total : selectedProduct.member_price,
          admin_price: selectedProduct?.product_type === 'pasca' ? inquiry?.admin : 0,
          payment_fee: selectedPayment?.totalFee ?? 0,
          payment_name: selectedPayment?.paymentName ?? 'Saldo',
          response_data: {
            ...inquiry,
            ...fieldSelected,
          },
          back_url: `${DASHBOARD_URL}/history`,
        },
        {
          headers: {
            Authorization: `Bearer ${getItem('access_token')}`,
            'Punyakios-Api-Key': generatePasswordHash(),
          },
        },
      )
      .then((res) => {
        // const { payment_methods } = res.data.data
        dispatch(getUserProfile());
        setIsModalOpen(false);
        setModalSuccess(true);
        // setLoadingPaymentMethods(false)
      })
      .catch((err) => {
        // setLoadingPaymentMethods(false)
      });
  };

  const isPasca = fieldSelected?.priceType === 'admin_fee';
  const amounPrice = selectedProduct?.product_type === 'pasca' ? inquiry?.total : selectedProduct?.member_price;
  // console.log(selectedProduct, 'selectedProduct');
  return (
    <div>
      {/* <Button
        onClick={showModal}
        title={isPasca ? 'Cek Tagihan' : 'Beli Sekarang'}
        disabled={disabled}
        loading={loadingInquiry}
      /> */}
      <Button
        onClick={() =>
          window.open('https://play.google.com/store/apps/details?id=com.punyakios&pcampaignid=web_share', '_blank')
        }
        title={'Beli Di aplikasi'}
        disabled={disabled}
        loading={loadingInquiry}
      />

      <Modal title={`Rincian Transaksi`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <div className={'mt-2 border-t py-4'}>
          <table width={'100%'}>
            <tr>
              <td width={'50%'} className="font-bold">
                Produk
              </td>
              <td width={'50%'}>: {selectedProduct?.product_original_name || product_type.replace('-', ' ')}</td>
            </tr>
            {/* {fieldSelected?.showPhone && (
              <tr>
                <td width={'50%'} className="font-bold">
                  Nomor Telepon
                </td>
                <td width={'50%'}>: {form.getFieldValue('phone_number')}</td>
              </tr>
            )} */}
            <tr>
              <td width={'50%'} className="font-bold">
                {fieldSelected?.label}
              </td>
              <td width={'50%'}>: {form.getFieldValue(fieldSelected?.name)}</td>
            </tr>

            {!selectedProduct?.category_name === 'Pascabayar' ? (
              <tr>
                <td width={'50%'} className="font-bold">
                  Harga Produk
                </td>
                <td width={'50%'}>: Rp {selectedProduct?.member_price}</td>
              </tr>
            ) : (
              <>
                {inquiry?.nama_pelanggan && (
                  <tr>
                    <td width={'50%'} className="font-bold">
                      Nama Pelanggan
                    </td>
                    <td width={'50%'}>: {inquiry?.nama_pelanggan}</td>
                  </tr>
                )}
                {inquiry?.name && (
                  <tr>
                    <td width={'50%'} className="font-bold">
                      Nama Pelanggan
                    </td>
                    <td width={'50%'}>: {inquiry?.name}</td>
                  </tr>
                )}
                {inquiry?.segment_power && (
                  <tr>
                    <td width={'50%'} className="font-bold">
                      Power/Daya
                    </td>
                    <td width={'50%'}>: {inquiry?.segment_power}</td>
                  </tr>
                )}
                {inquiry?.periode && (
                  <tr>
                    <td width={'50%'} className="font-bold">
                      Priode Tagihan
                    </td>
                    <td width={'50%'}>: {inquiry?.periode}</td>
                  </tr>
                )}
                {inquiry?.nilai_tagihan > 0 && (
                  <tr>
                    <td width={'50%'} className="font-bold">
                      Nilai Tagihan
                    </td>
                    <td width={'50%'}>: Rp {inquiry?.nilai_tagihan}</td>
                  </tr>
                )}
                {inquiry?.denda > 0 && (
                  <tr>
                    <td width={'50%'} className="font-bold">
                      Nilai Tagihan
                    </td>
                    <td width={'50%'}>: Rp {inquiry?.denda}</td>
                  </tr>
                )}

                {inquiry?.admin > 0 && (
                  <tr>
                    <td width={'50%'} className="font-bold">
                      Biaya Admin
                    </td>
                    <td width={'50%'}>: Rp {inquiry?.admin}</td>
                  </tr>
                )}

                <tr>
                  <td width={'50%'} className="font-bold">
                    Total
                  </td>
                  <td width={'50%'}>: {formatNumber(inquiry?.total || selectedProduct?.member_price, 'Rp. ')}</td>
                </tr>
              </>
            )}
          </table>

          <div className="mt-4 border-t pt-4">
            <div>
              {getItem('access_token') && (
                <div>
                  <h4 className="font-medium">Saldo</h4>
                  <PaymentMethodItem
                    image={'https://i.ibb.co/5v6Lf2N/Group-299.png'}
                    title={`Saldo (Rp. ${formatNumber(user?.balance)})`}
                    selected={selectedPayment === 1}
                    onClick={() => {
                      if (user?.balance > 0) {
                        return onSelectedPayment(1);
                      }

                      return message.error('Saldo tidak cukup, silahkan top up terlebih dahulu');
                    }}
                  />
                </div>
              )}

              {/* <div>
                <h4 className="font-medium">Transfer Bank (Manual)</h4>
                {manualPaymentMethods?.map((item, index) => (
                  <PaymentMethodItem
                    key={index}
                    image={item.paymentImage}
                    title={item.paymentName}
                    selected={selectedPayment?.id === item.id}
                    onClick={() => {
                      onSelectedPayment(item);
                      setIsManualPayment(true);
                    }}
                  />
                ))}
              </div> */}

              {/* <div>
                <h4 className="font-medium">Transfer Bank (Otomatis)</h4>
                {paymentMethods?.map((item, index) => (
                  <PaymentMethodItem
                    key={index}
                    image={item.paymentImage}
                    title={item.paymentName}
                    selected={selectedPayment?.paymentMethod === item.paymentMethod}
                    onClick={() => {
                      onSelectedPayment(item);
                      setIsManualPayment(false);
                    }}
                  />
                ))}
              </div>

              {loadingPaymentMethods && (
                <div className="w-full mx-auto text-center my-4">
                  <LoadingOutlined />
                  <span className="ml-2">Loading Metode Pembayaran</span>
                </div>
              )} */}
            </div>
            {/* <Form form={form} layout="vertical">
              <Form.Item required tooltip={"Pilih Metode Pembayaran"}>
                <Select
                  placeholder={"Pilih Metode Pembayaran"}
                  className={"rounded-lg"}
                >
                  <Select.Option value={"saldo"}>Saldo Akun</Select.Option>
                  <Select.Option value={"online-payment"}>
                    Online Payment
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form> */}
          </div>
          {/* <Button
            onClick={() => setModalPin(true)}
            title={isPasca ? 'Bayar Tagihan' : 'Beli Sekarang'}
            disabled={!selectedPayment || user?.balance < amounPrice}
          /> */}
          {/* <Button
            onClick={() => handleConfirmPayment()}
            title={isPasca ? 'Bayar Tagihan' : 'Beli Sekarang'}
            disabled={!selectedPayment || user?.balance < amounPrice}
          /> */}
        </div>
      </Modal>

      <Modal
        title={`Masukkan PIN Transaksi`}
        open={modalPin}
        onOk={() => setModalPin(false)}
        onCancel={() => setModalPin(false)}
        footer={null}
      >
        <Button
          onClick={() => setModalPin(true)}
          title={isPasca ? 'Bayar Tagihan' : 'Beli Sekarang'}
          disabled={!selectedPayment || user?.balance < amounPrice}
        />
      </Modal>

      {/* success transaction */}
      <Modal
        title={`Transaksi Berhasil Dibuat`}
        open={modalSuccess}
        onOk={() => setModalSuccess(false)}
        onCancel={() => setModalSuccess(false)}
        footer={null}
      >
        <Result
          status="success"
          title="Transaksi Berhasil Dibuat"
          subTitle="Silahkan cek detail transaksi di menu riwayat transaksi"
          extra={[
            <Button
              type="primary"
              key="console"
              title="Lihat Transaksi"
              onClick={() => {
                navigate('/history');
              }}
            />,
          ]}
        />
      </Modal>

      {/* modal tagihan */}
      <Modal
        title={`Informasi`}
        open={modalInquiry}
        onOk={() => setModalInquiry(false)}
        onCancel={() => setModalInquiry(false)}
        footer={null}
      >
        <Result
          status="error"
          title="Informasi"
          subTitle={responseInquiry?.message ?? 'Terjadi Kesalahan'}
          extra={[
            <Button
              type="primary"
              key="console"
              title="Tutup"
              onClick={() => {
                setModalInquiry(false);
              }}
            />,
          ]}
        />
      </Modal>
    </div>
  );
};

export default ModalDetailPembelian;

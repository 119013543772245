import { Button, Col, Form, Input, Row, message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthFormWrap } from './style';
import { toast } from 'react-toastify';

function ResetPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token, email } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = (values) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/reset-password`;
    setLoadingSubmit(true);
    axios
      .post(url, {
        ...values,
        token,
      })
      .then((res) => {
        const { data } = res.data;
        setLoadingSubmit(false);
        toast.success(res.data?.message);
        return navigate('/auth/login');
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          setLoadingSubmit(false);
          toast.error(data?.message);
        }
      });
  };

  return (
    <Row justify="center">
      <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
        <AuthFormWrap>
          <Form form={form} name="forgotPass" onFinish={handleSubmit} layout="vertical">
            <div className="ninjadash-authentication-top">
              <h2 className="ninjadash-authentication-top__title">Reset Password</h2>
            </div>
            <div className="ninjadash-authentication-content text-left">
              <Form.Item label="Email Address" name="email">
                <Input placeholder="name@example.com" defaultValue={email} disabled />
              </Form.Item>
              <Form.Item
                label="Kata Sandi baru"
                name="newPassword"
                rules={[{ required: true, message: 'Masukkan Kata Sandi Baru' }]}
              >
                <Input.Password placeholder="****" />
              </Form.Item>
              <Form.Item
                label="Konfirmasi Kata Sandi baru"
                name="confirmNewPassword"
                rules={[{ required: true, message: 'Masukkan Konfirmasi Kata Sandi' }]}
                className="mb-4"
              >
                <Input.Password placeholder="****" />
              </Form.Item>
              <Button
                className="btn-reset mt-4"
                onClick={() => form.submit()}
                type="primary"
                size="large"
                loading={loadingSubmit}
              >
                Ubah Kata Sandi
              </Button>
            </div>
            <div className="ninjadash-authentication-bottom">
              <p className="return-text">
                Return to <Link to="/auth/login">Sign In</Link>
              </p>
            </div>
          </Form>
        </AuthFormWrap>
      </Col>
    </Row>
  );
}

export default ResetPassword;

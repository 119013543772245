import { message } from 'antd';
import { DataService } from '../config/dataService/dataService';
import bcrypt from 'bcryptjs';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

const updateStatus = ({ url, data }) => {
  DataService.post(url, data)
    .then((res) => {
      message.success('Status berhasil diupdate');
    })
    .catch((err) => {
      message.error('Status gagal diupdate');
    });
};

function formatNumber(number, prefix = null) {
  // change number format it's number greater than 0
  if (number > 0) {
    const format = parseInt(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (prefix) {
      return `${prefix} ${format}`;
    }
    return format;
  } else {
    return 0;
  }
}

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
};

const subStr = (str, length = 25) => {
  if (str?.length > length) {
    return str?.substr(0, length) + '...';
  } else {
    return str;
  }
};

const inArray = (needle, haystack) => {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] == needle) return true;
  }
  return false;
};

const generatePasswordHash = (value = 'M^5@!bmC%@29&56r$d*ucM6z') => {
  // Buat objek hash menggunakan algoritma SHA-256
  const passwordHash = bcrypt.hashSync(value);

  // Kembalikan hash password
  return passwordHash.replace('$2a$10$', '');
};

export {
  ellipsis,
  idGenerator,
  updateStatus,
  formatNumber,
  getBase64,
  beforeUpload,
  subStr,
  inArray,
  generatePasswordHash,
};

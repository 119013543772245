import { Carousel } from 'antd';
import React from 'react';

const Banner = ({ data }) => {
  return (
    <Carousel autoplay>
      {data.map((item, index) => (
        <div className="w-full" key={index}>
          <a href={item.banner_url}>
            <img src={item.banner_image_url} className="w-full h-50 rounded-md object-cover" alt={item.banner_title} />
          </a>
        </div>
      ))}
    </Carousel>
  );
};

export default Banner;

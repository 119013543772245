import React from 'react';
import Navbar from '../../Components/Navbar';
import Button from '../../Components/Button';
import { Modal, Result, message } from 'antd';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../../../redux/authentication/actionCreator';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_URL } from '../../Utils/Constant';
import { getItem } from '../../../../utility/localStorageControl';
import { LoadingOutlined } from '@ant-design/icons';
import { getUserProfile } from '../../../../redux/user/actionCreator';
import { generatePasswordHash } from '../../../../utility/utility';

const VerificationEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalLogout, setModalLogout] = React.useState(false);
  const [otp, setOtp] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingResend, setLoadingResend] = React.useState(false);

  const user = getItem('user');

  const handleLogout = () => {
    message.success('Berhasil Logout');
    Cookies.remove('access_token');
    Cookies.remove('user');
    Cookies.remove('role');
    Cookies.remove('logedIn');
    return dispatch(logOut(() => navigate('/')));
  };

  const handleVerificationEmail = () => {
    setLoading(true);
    axios
      .post(
        `${API_URL}/user/email-verification`,
        {
          otp,
        },
        {
          headers: {
            Authorization: `Bearer ${getItem('access_token')}`,
            'Punyakios-Api-Key': generatePasswordHash(),
          },
        },
      )
      .then((res) => {
        const data = res.data;
        toast.success(data.message);
        dispatch(getUserProfile());
        setTimeout(() => {
          setLoading(false);
          navigate('/');
        }, 3000);
      })
      .catch((err) => {
        const { message } = err.response.data;
        setLoading(false);
        toast.error(message);
      });
  };

  const handleResendVerificationEmail = () => {
    setLoadingResend(true);
    axios
      .post(
        `${API_URL}/auth/resend-email`,
        {
          name: user.name,
          email: user.email,
        },
        {
          headers: {
            Authorization: `Bearer ${getItem('access_token')}`,
          },
        },
      )
      .then((res) => {
        const data = res.data;
        setLoadingResend(false);
        toast.success(data.message);
      })
      .catch((err) => {
        const { message } = err.response.data;
        setLoadingResend(false);
        toast.error(message);
      });
  };

  return (
    <div className="bg-[#f5f6fa]">
      <div className="  shadow-horizontal max-w-lg min-h-screen mx-auto my-0 bg-white">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full  rounded-lg md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 text-center  bg-white">
            <h1 className="text-2xl font-bold pt-4">Verifikasi Email</h1>
            <p className="text-sm font-normal">Silahkan Masukkan Kode OTP yang dikirim ke alamat email anda.</p>
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label for="otp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"></label>
                  <input
                    type="number"
                    name="otp"
                    value={otp}
                    id="otp"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Masukkan Kode OTP"
                    required=""
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>

                <Button
                  onClick={() => {
                    if (!loading || !loadingResend) {
                      return handleVerificationEmail();
                    }
                    return null;
                  }}
                  title={'Verifikasi Sekarang'}
                  loading={loading}
                  color="blue"
                />

                <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
                  Tidak Menerima Kode?
                  {loadingResend ? (
                    <LoadingOutlined />
                  ) : (
                    <a
                      href="#"
                      className="font-medium text-primary-600 hover:underline dark:text-primary-500 ml-2"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!loading || !loadingResend) {
                          return handleResendVerificationEmail();
                        }

                        return null;
                      }}
                    >
                      Kirim Ulang
                    </a>
                  )}
                </p>
              </form>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="max-w-lg mx-auto fixed inset-x-0 bottom-0 border-t  bg-white px-4 mb-4">
          <div className="mt-4">
            <Button onClick={() => setModalLogout(true)} title={'Keluar'} color="blue" />
            <Modal
              title={`Konfirmas Keluar`}
              open={modalLogout}
              onOk={() => setModalLogout(false)}
              onCancel={() => setModalLogout(false)}
              footer={null}
            >
              <Result
                status="warning"
                title="Konfirmas Keluar"
                subTitle="Apakah kamu yakin ingin keluar dari akun ini?"
                extra={[
                  <div className="flex items-start justify-center">
                    <div className="w-20">
                      <Button
                        type="primary"
                        key="console"
                        title="Batal"
                        onClick={() => {
                          setModalLogout(false);
                        }}
                      />
                    </div>
                    ,
                    <div className="w-20">
                      <Button
                        color="blue"
                        type="danger"
                        key="console"
                        title="Keluar"
                        onClick={() => {
                          handleLogout();
                        }}
                      />
                    </div>
                    ,
                  </div>,
                ]}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationEmail;

const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Setting Code',
    dataIndex: 'setting_code',
    key: 'setting_code',
  },
  {
    title: 'Setting Value',
    dataIndex: 'setting_value',
    key: 'setting_value',
  },
];

export { dataTableColumn };

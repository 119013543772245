import { DashboardFilled, LogoutOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Drawer, Layout, Menu, message } from 'antd';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logOut } from '../../../../redux/authentication/actionCreator';
import { getItem } from '../../Utils/helper';
import './navbar.css';

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { pathname: location } = useLocation();
  const isLoggin = getItem('access_token');
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(!visible);
  };
  // If you do not want to auto-close the mobile drawer when a path is selected
  // Delete or comment out the code block below
  // From here
  useEffect(() => {
    setVisible(false);
  }, [location]);

  return (
    <nav className="navbar">
      <Layout>
        <Layout.Header className="nav-header">
          <div className="logo">
            <h3 className="brand-font">PPOB</h3>
          </div>
          <div className="navbar-menu">
            <div className="leftMenu">
              <LeftMenu mode={'horizontal'} />
            </div>
            <Button className="menuButton" type="text" onClick={showDrawer}>
              <MenuOutlined />
            </Button>
            <div className="rightMenu">
              <RightMenu isLoggin={isLoggin} mode={'horizontal'} />
            </div>

            <Drawer
              title={'PPOB'}
              placement="right"
              closable={true}
              onClose={showDrawer}
              open={visible}
              style={{ zIndex: 99999 }}
            >
              <MenuLogin mode={'inline'} isLoggin={isLoggin} />
            </Drawer>
          </div>
        </Layout.Header>
      </Layout>
    </nav>
  );
};

const RightMenu = ({ mode, isLoggin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    message.success('Berhasil Logout');
    Cookies.remove('access_token');
    Cookies.remove('user');
    Cookies.remove('role');
    Cookies.remove('logedIn');
    return dispatch(logOut(() => navigate('/')));
  };

  if (!isLoggin) {
    if (mode === 'inline') {
      return (
        <Menu mode={mode}>
          <Menu.Item key="project" className="flex items-center" onClick={() => navigate('/auth/login')}>
            <span className="mt-4">Masuk</span>
          </Menu.Item>
          <Menu.Item key="project" className="flex items-center" onClick={() => navigate('/auth/register')}>
            <span className="mt-4">Daftar</span>
          </Menu.Item>
        </Menu>
      );
    }
    return (
      <a
        href="#"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        onClick={(e) => {
          e.preventDefault();
          navigate('/auth/login');
        }}
      >
        Log in
      </a>
    );
  }

  return (
    <Menu mode={mode} style={{ backgroundColor: 'transparent' }}>
      <Menu.SubMenu
        title={
          <>
            <Avatar icon={<UserOutlined />} />
            <span className="username">John Doe</span>
          </>
        }
      >
        <Menu.Item key="project" onClick={() => (window.location.href = '/admin')}>
          <DashboardFilled />
          <span className="mt-4">Dashboard</span>
        </Menu.Item>
        <Menu.Item key="log-out" onClick={() => handleLogout()}>
          <LogoutOutlined />
          <span>Logout</span>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

const LeftMenu = ({ mode }) => {
  const navigate = useNavigate();
  return (
    <Menu mode={mode}>
      <Menu.Item key="home" onClick={() => navigate('/')}>
        Home
      </Menu.Item>
      <Menu.Item key="price" onClick={() => navigate('/price')}>
        Harga
      </Menu.Item>
      <Menu.Item key="history" onClick={() => navigate('/history')}>
        History
      </Menu.Item>
    </Menu>
  );
};

const MenuLogin = ({ mode, isLoggin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    message.success('Berhasil Logout');
    Cookies.remove('access_token');
    Cookies.remove('user');
    Cookies.remove('role');
    Cookies.remove('logedIn');
    return dispatch(logOut(() => navigate('/')));
  };
  if (isLoggin) {
    return (
      <Menu mode={mode}>
        <Menu.Item key="home" onClick={() => (window.location.href = '/admin')}>
          Dashboard
        </Menu.Item>
        <Menu.Item key="home" onClick={() => navigate('/')}>
          Beranda
        </Menu.Item>
        <Menu.Item key="price" onClick={() => navigate('/price')}>
          Harga
        </Menu.Item>
        <Menu.Item key="history" onClick={() => navigate('/history')}>
          History
        </Menu.Item>
        <Menu.Item key="history" onClick={() => handleLogout()}>
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Menu mode={mode}>
      <Menu.Item key="home" onClick={() => navigate('/')}>
        Beranda
      </Menu.Item>
      <Menu.Item key="price" onClick={() => navigate('/price')}>
        Harga
      </Menu.Item>
      <Menu.Item key="history" onClick={() => navigate('/history')}>
        History
      </Menu.Item>
    </Menu>
  );
};

export default Navbar;

/* eslint-disable react/jsx-no-bind */
import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row, Select, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { DataService } from '../../../config/dataService/dataService';
import { BorderLessHeading, Main } from '../../styled';
import { formatNumber } from '../../client/Utils/helper';

function TransactionTopUpPage() {
  const PageRoutes = [
    {
      path: '/',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '#',
      breadcrumbName: 'Transaction',
    },
    {
      path: '/deposit/topup',
      breadcrumbName: 'Top up',
    },
  ];

  const navigation = useNavigate();
  const [form] = Form.useForm();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [step, setStep] = useState(1);

  const loadPaymentManual = (value) => {
    const url = '/user/deposit/payment-list';
    setLoadingPayment(true);
    DataService.post(url, { ...value })
      .then((res) => {
        setStep(2);
        message.success(res.data.message);
        const { payment_methods } = res.data.data;
        setPaymentMethods(payment_methods);
        setLoadingPayment(false);
      })
      .catch((err) => {
        const { data } = err.response;
        setLoadingPayment(false);
        message.error(data?.message);
      });
  };

  const handleSubmit = (value) => {
    if (value.deposit_amount < 10000) {
      return message.error('Minimal deposit Rp. 1.0000');
    }
    loadPaymentManual(value);
  };

  const createDeposit = () => {
    const url = '/user/deposit/create';
    setLoadingSubmit(true);
    DataService.post(url, {
      deposit_amount: form.getFieldValue('deposit_amount'),
      payment: selectedPayment,
    })
      .then((res) => {
        const { reference } = res.data.data;
        setStep(1);
        message.success(res.data.message);
        setLoadingSubmit(false);
        return navigation(`/admin/deposit/${reference}`);
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          setLoadingSubmit(false);
          message.error(data?.message);
        }
      });
  };

  const loading = loadingPayment || loadingSubmit;

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Top Up" routes={PageRoutes} />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <BorderLessHeading>
              <Cards title="Create Deposit">
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={handleSubmit}
                  //   onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row gutter={15}>
                    <Col xs={24}>
                      {/* alert */}
                      <Alert
                        // icon={<UilLayers />}
                        description="Minimal Deposit Rp. 10.000,"
                        type="info"
                        style={{ marginBottom: 20 }}
                      />
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Nominal Deposit" name="deposit_amount">
                        <Input
                          size="small"
                          style={{ paddingTop: 6, paddingBottom: 6 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Nominal!',
                            },
                            {
                              // set minimum deposit
                              min: 10000,
                              message: 'Minimal Deposit Rp. 10.000',
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    {paymentMethods.length > 0 && (
                      <Col xs={24}>
                        <Form.Item
                          label="Payment Method"
                          name="payment_method"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Payment Method!',
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            className="w-full mb-2"
                            placeholder="Pilih Payment Method"
                            onChange={(e) => {
                              const payment = paymentMethods.find((item) => item.paymentMethod === e);
                              setSelectedPayment(payment);
                            }}
                          >
                            {paymentMethods.map((item) => (
                              <Select.Option key={item.paymentMethod} value={item.paymentMethod}>
                                {item.paymentName} - ({formatNumber(item.totalFee, 'Rp ')})
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24}>
                      <Button type="secondary" key="back" onClick={() => navigation('/admin/deposit/history')}>
                        Batal
                      </Button>
                      ,
                      {loading ? (
                        <Button type={'primary'} key="submit">
                          <LoadingOutlined />
                        </Button>
                      ) : (
                        <Button
                          type={'primary'}
                          key="submit"
                          onClick={() => {
                            if (step === 1) {
                              return form.submit();
                            }

                            return createDeposit();
                          }}
                        >
                          {step === 1 ? 'Pilih Pembayaran' : 'Deposit'}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Cards>
            </BorderLessHeading>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default TransactionTopUpPage;

import { faArrowLeft, faHistory, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CardInfo from '../../../../components/cardInfo';
import { DataService } from '../../../../config/dataService/dataService';
import { getItem } from '../../../../utility/localStorageControl';
import Button from '../../Components/Button';
import Loading from '../../Components/Loading';
import { formatNumber } from '../../Utils/helper';
import { ReactComponent as NeedLogin } from '../../assets/ilustration/need-login.svg';
import { ReactComponent as EmptyStateTransaction } from '../../assets/ilustration/no-transaction.svg';

const BalanceClient = () => {
  const navigate = useNavigate();
  const user = getItem('user');
  const [state, setState] = React.useState({
    data: [],
    loading: false,
    total: 0,
    currentPage: 1,
    search: null,
  });

  const loadData = (url = '/transaction/deposit', perpage = 10, params = { page: state.currentPage }) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(url, { perpage, ...params })
      .then((res) => {
        const { data, total, current_page } = res.data.data;
        setState((currentState) => ({
          ...currentState,
          loading: false,
          data,
          total,
          currentPage: current_page,
        }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onChange = (page, pageSize = 10) => {
    loadData(`/transaction/deposit/?page=${page}`, pageSize, {
      search: state.search,
      page,
    });
  };

  const { data, currentPage, total, loading } = state;

  return (
    <div className="bg-[#ecf0f1]">
      <div className=" bg-[#f5f6fa] shadow-horizontal max-w-lg min-h-screen mx-auto my-0">
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl absolute left-4 cursor-pointer"
                  onClick={() => {
                    navigate('/');
                  }}
                />
                <span className="capitalize text-md font-bold">Saldo</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* need login */}
        {!getItem('access_token') && (
          <div className="flex flex-col justify-center items-center h-96 mt-8">
            <NeedLogin className="w-[50%]" />
            <p className="mt-4 w-50 text-center text-md font-medium">Kamu Belum Masuk ke Akun</p>
            <p className="mt-4 w-96 text-center">
              Masuk ke akun kamu dulu yuk biar bisa lihat profilmu dan mengakses semua fitur menarik dari Punyakios
            </p>
            <div className="w-40 mt-8">
              <Button onClick={() => navigate('/auth/login')} title={'Masuk Sekarang'} />
            </div>
          </div>
        )}

        {getItem('access_token') && (
          <>
            <div className=" px-2">
              {/* card info */}
              <div className="flex justify-between items-center mb-0">
                <div className="w-full p-2 pb-0">
                  <CardInfo
                    item={{
                      title: 'Saldo',
                      total: formatNumber(user?.balance, 'Rp '),
                      image: 'https://img.icons8.com/bubbles/100/money-bag.png',
                    }}
                  />
                </div>
              </div>

              {/* button top up */}
              <div className="flex justify-between items-center mb-2">
                <div className="w-1/2 p-2">
                  <Button
                    onClick={() => navigate('/topup')}
                    title={'Top Up'}
                    icon={<FontAwesomeIcon icon={faPlusCircle} className="text-sm" />}
                  />
                </div>
                <div className="w-1/2 p-2">
                  <Button title={'Mutasi'} icon={<FontAwesomeIcon icon={faHistory} className="text-sm" />} disabled />
                </div>
              </div>
            </div>
            {/* product loading */}
            {loading && (
              <div className="flex justify-center items-center h-[80vh]">
                <Loading />
              </div>
            )}

            {/* product list */}
            {!loading && (
              <div className="container mx-auto " style={{ paddingBottom: 100 }}>
                <p className="mb-0 text-lg font-bold mx-4">History Top Up</p>
                {data.map((item, index) => {
                  return (
                    <div
                      className={`mx-4 bg-white  rounded-lg mt-2 cursor-pointer`}
                      key={item?.id}
                      onClick={() => navigate('/topup/detail/' + item.ref)}
                    >
                      <div className="flex justify-between items-center px-4 py-2">
                        <div>
                          <p className="mb-0 text-sm font-bold">{item.trx_id}</p>
                          <p className="mb-0 text-xs">{item.created_payment}</p>
                        </div>
                        <Tag color={item.status_color} className="mr-0">
                          {item.status_name}
                        </Tag>
                      </div>
                      <div className="flex justify-between items-center px-4 py-2 border-t">
                        <p className="mb-0 text-sm font-bold">Nominal Top Up</p>
                        <p className="mb-0 text-sm font-bold">{formatNumber(item.deposit_amount, 'Rp ')}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {/* empty state */}
            {!loading && data && data.length < 1 && (
              <div className="flex flex-col justify-center items-center h-[80vh]">
                <EmptyStateTransaction className="w-[80%]" />
                <p className="mt-4 w-60 text-center">Sayangnya, kamu belum pernah beli apa-apa di Punyakios :</p>
                <div className="w-40 mt-8">
                  <Button onClick={() => navigate('/product/lainnya')} title={'Beli Sekarang'} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BalanceClient;

import propTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Cards } from '../cards/frame/cards-frame';

const CardWrapper = styled.figure`
  display: flex;
  margin: 0;
  position: relative;
  h2,
  p {
    margin: 0;
  }
  figcaption {
    .more {
      position: absolute;
      top: 0px;
      ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
      a {
        color: ${({ theme }) => theme['extra-light-color']};
      }
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      color: ${({ theme }) => theme['light-color']};
    }
  }
`;

const Icon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, className }) => theme[`${className}-color`]};
  ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
`;

function CardInfo({ item }) {
  const { title, total, image, className } = item;
  return (
    <Cards headless className={'shadow'}>
      <CardWrapper>
        <Icon className={className}>
          <img src={image} alt={title} style={{ height: 50 }} />
        </Icon>
        <figcaption>
          <h2>{total}</h2>
          <p>{title}</p>
        </figcaption>
      </CardWrapper>
    </Cards>
  );
}

CardInfo.propTypes = {
  item: propTypes.object,
};

CardInfo.defaultProps = {
  item: {
    id: 1,
    total: '47',
    title: 'Total tasks',
    image: 'https://img.icons8.com/bubbles/100/cancel--v2.png',
    className: 'primary',
  },
};

export default CardInfo;

/* eslint-disable react/jsx-no-bind */
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import UilEllipsisH from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import { Button, Col, Form, message, Pagination, Result, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../components/atoms/ConfirmModal';
import ModalDetail from '../../components/atoms/ModalDetail';

import { Cards } from '../../components/cards/frame/cards-frame';
import { Dropdown } from '../../components/dropdown/dropdown';
import { PageHeader } from '../../components/page-headers/page-headers';
import DataTable from '../../components/table/DataTable';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { inArray } from '../../utility/utility';
import { BorderLessHeading, Main } from '../styled';
import ModalForm from './components/ModalForm';
import { dataTableColumn } from './config';

function ResellerPage() {
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const [state, setState] = React.useState({
    data: [],
    loading: false,
    total: 0,
    currentPage: 1,
    search: null,
  });

  const PageRoutes = [
    {
      path: '/',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '/master/reseller',
      breadcrumbName: 'Reseller',
    },
  ];

  const loadData = (url = '/reseller/list', perpage = 10, params = { page: state.currentPage }) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(url, { perpage, ...params })
      .then((res) => {
        const { data, total, current_page } = res.data.data;
        setState((currentState) => ({
          ...currentState,
          loading: false,
          data,
          total,
          currentPage: current_page,
        }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onChange = (page, pageSize = 10) => {
    loadData(`/reseller/list/?page=${page}`, pageSize, {
      search: state.search,
      page,
    });
  };

  const registerReseller = (values) => {
    setLoadingSubmit(true);

    DataService.post('/reseller/register', values)
      .then((res) => {
        setLoadingSubmit(false);
        form.resetFields();
        loadData();
        Cookies.set('user', JSON.stringify(res.data.data));
        message.success(res.data.message);
      })
      .catch((err) => {
        const { data } = err.response;
        setLoadingSubmit(false);
        message.error(data?.message);
      });
  };

  const { data, currentPage, total, search, loading } = state;

  const user = getItem('user');

  if (inArray(getItem('role'), ['member'])) {
    if (inArray(user.status_reseller, ['waiting'])) {
      return (
        <>
          <PageHeader className="ninjadash-page-header-main" title="Reseller" routes={PageRoutes} />
          <Main>
            <Row gutter={15}>
              <Col xs={24}>
                <Cards>
                  <Result
                    status={'info'}
                    title="Menunggu Persetujuan"
                    subTitle="Status Permohonan Reseller Anda Sedang Menunggu Persetujuan Admin"
                    extra={[
                      <Link to={'/admin'}>
                        <Button type={'primary'} key="buy">
                          Kembali Ke Dashboard
                        </Button>
                      </Link>,
                    ]}
                  />
                </Cards>
              </Col>
            </Row>
          </Main>
        </>
      );
    }

    if (inArray(user.status_reseller, ['approved'])) {
      return (
        <>
          <PageHeader className="ninjadash-page-header-main" title="Reseller" routes={PageRoutes} />
          <Main>
            <Row gutter={15}>
              <Col xs={24}>
                <Cards>
                  <Result
                    status="success"
                    title="Akun Reseller Anda Telah Diaktifkan"
                    subTitle="Selamat Anda Telah Menjadi Reseller Kami, Akun Reseller Anda Telah Diaktifkan"
                    extra={[
                      <Link to={'/admin/transaction/deposit'}>
                        <Button type={'primary'} key="buy">
                          Mulai Transaksi
                        </Button>
                      </Link>,
                    ]}
                  />
                </Cards>
              </Col>
            </Row>
          </Main>
        </>
      );
    }

    if (!user.status_reseller || inArray(user.status_reseller, ['rejected'])) {
      return (
        <>
          <PageHeader className="ninjadash-page-header-main" title="Reseller" routes={PageRoutes} />
          <Main>
            <Row gutter={15}>
              <Col xs={24}>
                <Cards title="Form Permohonan Reseller">
                  {inArray(user.status_reseller, ['rejected']) && (
                    <Result
                      style={{ marginBottom: 40 }}
                      status="error"
                      title="Permohonan Reseller Ditolak"
                      subTitle="Status Permohonan Reseller Anda Ditolak, Silahkan Mengisi Form Permohonan Reseller Kembali"
                    />
                  )}
                  <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    //   onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    onFinish={(values) => registerReseller(values)}
                  >
                    <Row gutter={15}>
                      <Col xs={24}>
                        <h3>Ketentuan menjadi reseller</h3>
                        <p>
                          1. Minimal 0 transaksi per bulan dan apabila dalam satu bulan transaksi kurang dari 0 maka
                          status reseller otomatis akan dinonaktifkan
                        </p>
                        <p>2. Pembayaran menggunakan Saldo Akun.</p>

                        <div>
                          <p>
                            Anda dapat mengirimkan permintaan untuk menjadi reseller kami melalui form di bawah ini.
                            Permintaan Anda akan segera kami review.
                          </p>
                        </div>
                        <Form.Item name="reseller_description">
                          <TextArea size="small" rows={5} />
                        </Form.Item>

                        <Button onClick={() => form.submit()} type="primary" loading={loadingSubmit}>
                          <span className="ml-2">Kirim Permintaan</span>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Cards>
              </Col>
            </Row>
          </Main>
        </>
      );
    }
  }

  if (inArray(getItem('role'), ['reseller'])) {
    return (
      <>
        <PageHeader className="ninjadash-page-header-main" title="Reseller" routes={PageRoutes} />
        <Main>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards>
                <Result
                  status="success"
                  title="Kamu Telah Menjadi Reseller"
                  subTitle="Selamat Anda Telah Menjadi Reseller Kami, Silahkan Melakukan Transaksi"
                  extra={[
                    <Link to={'/admin/transaction/deposit'}>
                      <Button type={'primary'} key="buy">
                        Mulai Transaksi
                      </Button>
                    </Link>,
                  ]}
                />
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Reseller" routes={PageRoutes} />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <BorderLessHeading>
              <Cards title="List Request Reseller">
                <DataTable
                  filterOption
                  loading={loading}
                  tableData={data}
                  filterOnchange={(e) => setState((currentState) => ({ ...currentState, search: e }))}
                  onSearch={(e) => {
                    loadData(`/reseller/list`, 10, {
                      search: e || search,
                    });
                  }}
                  refetch={() => loadData()}
                  columns={[
                    ...dataTableColumn,
                    {
                      title: 'Actions',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      render: (text, record, index) => {
                        return (
                          <div>
                            <Dropdown
                              content={
                                <>
                                  <ModalDetail
                                    title="Detail Reseller Request"
                                    body={record?.reseller?.reseller_description}
                                  >
                                    <Link to="#">
                                      <EyeOutlined />
                                      Detail
                                    </Link>
                                  </ModalDetail>
                                  {inArray(record?.status_reseller, ['waiting']) && (
                                    <ModalForm
                                      initialValues={{ status: 'rejected' }}
                                      url={`/reseller/verify/${record.id}`}
                                      refetch={() => loadData()}
                                    />
                                  )}
                                  {inArray(record?.status_reseller, ['waiting']) && (
                                    <ConfirmModal
                                      url={`/reseller/verify/${record.id}`}
                                      refetch={() => loadData()}
                                      body={'Apakah anda yakin ingin menerima request reseller ini?'}
                                      _method={'POST'}
                                      form={{ status: 'approved' }}
                                    >
                                      <Link to="#">
                                        <CheckOutlined />
                                        Approve
                                      </Link>
                                    </ConfirmModal>
                                  )}
                                </>
                              }
                            >
                              <Link to="#">
                                <UilEllipsisH />
                              </Link>
                            </Dropdown>
                          </div>
                        );
                      },
                    },
                  ]}
                />
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  total={total}
                  className="mt-4 text-center"
                  onChange={onChange}
                  pageSizeOptions={['10', '20', '50', '100', '200', '500']}
                />
              </Cards>
            </BorderLessHeading>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default ResellerPage;

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { Col, Form, Input, Row, Upload, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/buttons/buttons';
import { Modal } from '../../../../components/modals/antd-modals';
import { DataService } from '../../../../config/dataService/dataService';
import { getBase64 } from '../../../../utility/utility';

const ModalForm = ({ refetch, initialValues = {}, update = false, url, method = 'PUT' }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // state image
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileList, setFileList] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = ({ fileList }) => {
    const list = fileList.pop();
    setLoadingImage(true);
    setTimeout(() => {
      getBase64(list.originFileObj, (url) => {
        setLoadingImage(false);
        setImageUrl(url);
      });
      setFileList(list.originFileObj);
    }, 1000);
  };

  const handleSubmit = (value) => {
    setLoading(true);
    let formData = new FormData();

    if (fileList) {
      formData.append('banner_image', fileList);
    }

    formData.append('banner_title', value.banner_title);
    formData.append('banner_url', value.banner_url);
    formData.append('banner_description', value.banner_description);
    formData.append('status', initialValues?.status ?? 1);
    formData.append('_method', method);

    DataService.post(url, formData, { 'Content-Type': 'multipart/form-data' })
      .then((res) => {
        setLoading(false);
        form.resetFields();
        setIsModalOpen(false);
        refetch();
        message.success(res.data.message);
      })
      .catch((err) => {
        const { data } = err.response;
        setLoading(false);
        message.error(data?.message);
      });
  };

  useEffect(() => {
    if (initialValues?.banner_image_url) {
      setImageUrl(initialValues?.banner_image_url);
    }
  }, []);

  return (
    <>
      {update ? (
        <Link
          to="#"
          onClick={(e) => {
            e?.stopPropagation();
            showModal();
          }}
        >
          <span>
            <UilEdit />
            <span>Edit</span>
          </span>
        </Link>
      ) : (
        <Button onClick={() => showModal()} type="primary">
          <PlusOutlined color="#fff" />
          <span className="ml-2">Data Baru</span>
        </Button>
      )}
      <Modal
        title={update ? 'Edit Banner' : 'Tambah Banner Baru'}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
        width={1000}
        confirmLoading={loading}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{
            ...initialValues,
          }}
          onFinish={handleSubmit}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={12}>
              <Form.Item label="Title" name="banner_title">
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>

              <Form.Item label="Image" name="banner_image">
                <Upload
                  name="banner_image"
                  listType="picture-card"
                  className="avatar-uploader w-full"
                  showUploadList={false}
                  multiple={false}
                  beforeUpload={() => false}
                  onChange={(e) => handleChange(e)}
                >
                  {imageUrl ? (
                    loadingImage ? (
                      <LoadingOutlined />
                    ) : (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: '100%',
                        }}
                      />
                    )
                  ) : (
                    <div style={{ width: '100%' }}>
                      {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                      <div
                        style={{
                          marginTop: 8,
                          width: '100%',
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Banner Url" name="banner_url">
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Description" name="banner_description">
                <TextArea style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;

import Cookies from 'js-cookie';
import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { updateUser } = actions;

const getUserProfile = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get('/user/profile');
      const { user } = response.data;
      dispatch(updateUser(user));
      Cookies.set('user', JSON.stringify(user));
      if (!user.is_email_verified) {
        // return (window.location = '/akun/email-verification');
      }
    } catch (err) {
      // const { response } = err;
    }
  };
};

export { getUserProfile };

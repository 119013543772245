import { Form, Input, Layout, Select, Tabs } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import ProductItemDetail from '../../Components/ProductItemDetail';
import { API_URL } from '../../Utils/Constant';
import { formatNumber, inArray, removeDuplicates } from '../../Utils/helper';
import ModalDetailPembelian from './components/ModalDetailPembelian';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { generatePasswordHash } from '../../../../utility/utility';

const Fields = [
  {
    ['game']: {
      label: 'Id Game + Server',
      placeholder: 'ex: 123456:1',
      tooltip: 'Id Game dan Server dipisahkan dengan titik dua (:)',
      showPhone: true,
      priceType: 'price',
      name: 'id_game',
      showProduct: false,
    },
    ['voucher-game']: {
      label: 'Id Game',
      placeholder: 'ex: 123456',
      tooltip: 'Id Game',
      showPhone: true,
      priceType: 'price',
      name: 'id_game',
      showProduct: false,
    },
    ['pulsa']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Nomor telepon yang akan diisi pulsa',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['data']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Nomor telepon yang akan diisi paket data',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['token-listrik']: {
      label: 'Nomor Meter',
      placeholder: 'ex: 1234567890',
      tooltip: 'Nomor meter listrik',
      showPhone: true,
      priceType: 'price',
      name: 'nomor_meter',
      showProduct: false,
    },
    ['pln-pascabayar']: {
      label: 'ID Pelanggan',
      placeholder: 'ex: 1234567890',
      tooltip: 'ID Pelanggan PLN',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: false,
    },
    ['bpjs-kesehatan']: {
      label: 'Nomor BPJS',
      placeholder: 'ex: 1234567890',
      tooltip: 'Nomor BPJS Kesehatan',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: false,
    },
    ['internet-pascabayar']: {
      label: 'Nomor Pelanggan',
      placeholder: 'ex: 1234567890',
      tooltip: 'Nomor Pelanggan',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: true,
    },
    ['tv-pascabayar']: {
      label: 'Nomor Pelanggan',
      placeholder: 'ex: 1234567890',
      tooltip: 'Nomor Pelanggan',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: true,
    },
    ['pdam']: {
      label: 'ID Pelanggan',
      placeholder: 'ex: 1234567890',
      tooltip: 'ID Pelanggan PDAM',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: true,
    },
    ['hp-pascabayar']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 1234567890',
      tooltip: 'Nomor Telepon Pascabayar',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: true,
    },
    ['multifinance']: {
      label: 'Nomor Tujuan',
      placeholder: 'ex: 1234567890',
      tooltip: 'Masukkan Nomor Tujuan yang tertera di tagihan kamu',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: true,
    },
    ['pbb']: {
      label: 'Nomor Objek Pajak',
      placeholder: 'ex: 1234567890',
      tooltip: 'Nomor Objek Pajak',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: true,
    },
    ['gas-negara']: {
      label: 'Nomor Pelanggan',
      placeholder: 'ex: 1234567890',
      tooltip: 'Nomor Pelanggan Gas Negara',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: true,
    },
    ['bpjs-ketenagakerjaan']: {
      label: 'Nomor BPJS',
      placeholder: 'ex: 1234567890',
      tooltip: 'Nomor BPJS Ketenagakerjaan',
      showPhone: true,
      priceType: 'admin_fee',
      name: 'id_pelanggan',
      showProduct: true,
    },
    ['e-wallet']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Nomor telepon yang akan diisi saldo e-wallet',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['sms-telpon']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Nomor telepon yang akan diisi paket sms dan telpon',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['aktivasi-voucher']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Nomor telepon yang akan di aktivasi voucher',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['masa-aktif']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Nomor telepon yang akan di tambah masa aktif',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['aktivasi-perdana']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Nomor telepon yang akan di aktivasi',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['bundling']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Nomor telepon yang akan diisi bundling',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['tv']: {
      label: 'Id Pelanggan',
      placeholder: 'ex: 081234567890',
      tooltip: 'Masukkan ID Pelanggan yang tertera di STB kamu.',
      showPhone: false,
      priceType: 'price',
      name: 'id_pelanggan_stb',
      showProduct: false,
    },
    ['streaming']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Masukkan Nomor Telepon.',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['pertagas']: {
      label: 'Id Pelanggan',
      placeholder: 'ex: 081234567890',
      tooltip: 'Masukkan ID Pelanggan Pertagas Kamu.',
      showPhone: false,
      priceType: 'price',
      name: 'id_pelanggan_gas',
      showProduct: false,
    },
    ['voucher']: {
      label: 'Nomor Telepon',
      placeholder: 'ex: 081234567890',
      tooltip: 'Masukkan Nomor Telepon kamu.',
      showPhone: false,
      priceType: 'price',
      name: 'phone_number',
      showProduct: false,
    },
    ['mobile-legends']: {
      label: 'Id Game + Server',
      placeholder: 'ex: 123456:1',
      tooltip: 'Id Game dan Server dipisahkan dengan titik dua (:)',
      showPhone: true,
      priceType: 'price',
      name: 'id_game',
    },
    ['garena']: {
      label: 'ID Garena',
      placeholder: 'ex: 123456789',
      tooltip: 'Masukkan ID Garena kamu',
      showPhone: true,
      priceType: 'price',
      name: 'id_game',
    },
    ['free-fire']: {
      label: 'ID Game',
      placeholder: 'ex: 123456789',
      tooltip: 'Masukkan ID Game Free Fire kamu',
      showPhone: true,
      priceType: 'price',
      name: 'id_game',
    },
    ['pubg-mobile']: {
      label: 'ID Game',
      placeholder: 'ex: 123456789',
      tooltip: 'Masukkan ID Game PUBG kamu',
      showPhone: true,
      priceType: 'price',
      name: 'id_game',
    },
  },
];

const CheckoutClient = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { product_type = 'pulsa', product = 'TELKOMSEL' } = useParams();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [products, setProducts] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const loadProducts = () => {
    axios
      .get(`${API_URL}/product/list/${product}/${product_type}`, {
        headers: {
          'Punyakios-Api-Key': generatePasswordHash(),
        },
      })
      .then((res) => {
        setProducts(res.data.data);
        setTabs(res.data.tabs);
        if (res.data.data.length > 0) {
          setActiveTab(res.data.data[0]['group']);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const field = Fields[0][product] || Fields[0][product_type];
  console.log(field, product, 'field');
  const tabsData = products.map((item) => item.group);
  return (
    <div className="bg-[#f5f6fa]">
      <div className="  shadow-horizontal max-w-lg min-h-screen mx-auto my-0 bg-white">
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-start items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl cursor-pointer"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <span className="capitalize ml-2 text-md font-bold">{product.replace('-', ' ')}</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        <div className="  ">
          <div className=" p-4 bg-white rounded-lg">
            <div className="border-b">
              <Form form={form} layout="vertical">
                <Form.Item label={field?.label} required tooltip={field?.tooltip} name={field?.name}>
                  <Input placeholder={field?.placeholder} className="rounded-lg" />
                </Form.Item>
                {/* {field?.showPhone && (
                  <Form.Item label="Nomor Telepon" name={'phone_number'} required>
                    <Input placeholder="Input Nomor Telepon" className="rounded-lg" />
                  </Form.Item>
                )} */}
                {field?.showProduct && (
                  <Form.Item label="Layanan" name={'service'} required>
                    <Select
                      placeholder="Pilih Layanan"
                      className="rounded-lg"
                      onChange={(e) => {
                        setSelectedProduct(products.find((item) => item.id === e));
                      }}
                    >
                      {products.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item.id}>
                            {item.product_original_name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
              </Form>
            </div>
          </div>
          {!inArray(field?.name, ['id_pelanggan']) && (
            <div className="flex overflow-x-auto  w-full mb-4 pb-4">
              {removeDuplicates(tabsData).length > 1 &&
                removeDuplicates(tabsData).map((item, index) => {
                  return (
                    <div
                      className={`flex-shrink-0 px-2 py-1 cursor-pointer text-center rounded-lg border-2 ml-4 px-4 ${
                        activeTab == item ? 'border-orange-500 ' : 'border-gray-200'
                      }`}
                      onClick={() => setActiveTab(item)}
                    >
                      {item}
                    </div>
                  );
                })}
            </div>
          )}

          {!inArray(field?.name, ['id_pelanggan']) && (
            <div className=" px-2 rounded-lg " style={{ paddingBottom: 100 }}>
              <div className="flex justify-start items-center flex-wrap">
                {products
                  .filter((row) => row.group == activeTab)
                  .map((row, index) => {
                    return (
                      <ProductItemDetail
                        label={row.product_original_name}
                        price={formatNumber(row?.member_price, 'Rp. ')}
                        onClick={() => setSelectedProduct(row)}
                        selected={selectedProduct?.id === row.id}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <div className="max-w-lg mx-auto fixed inset-x-0 bottom-0 border-t  bg-white ">
          <div className=" inset-x-0 bottom-0 border-t">
            <div className="mx-4 py-2 bg-white rounded">
              <div className="flex justify-between items-center">
                <div>
                  {!inArray(field?.name, ['id_pelanggan']) && (
                    <h5 className="font-bold text-xl">{formatNumber(selectedProduct?.member_price, 'Rp. ')}</h5>
                  )}
                </div>
                <ModalDetailPembelian
                  product_type={product_type}
                  onSelectedPayment={(e) => setSelectedPayment(e)}
                  selectedPayment={selectedPayment}
                  disabled={!inArray(field?.name, ['id_pelanggan']) && !selectedProduct}
                  fieldSelected={field}
                  form={form}
                  selectedProduct={products.length > 1 ? selectedProduct : products[0]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutClient;

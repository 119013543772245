import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from '../../Components/Banner';
import { API_URL } from '../../Utils/Constant';
// Import Swiper styles
import { BellOutlined } from '@ant-design/icons';
import { faChevronRight, faClipboardList, faHomeAlt, faUserAlt, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import 'swiper/css';
import { loadProductCategory } from '../../../../redux/product/actionCreator';
import { getUserProfile } from '../../../../redux/user/actionCreator';
import { getItem } from '../../../../utility/localStorageControl';
import Button from '../../Components/Button';
import Product from '../../Components/Product';
import { checkEmailVerivied, formatNumber } from '../../Utils/helper';
import { generatePasswordHash } from '../../../../utility/utility';

const HomeClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [banners, setBanners] = React.useState([]);
  const [showBalance, setShowBalance] = React.useState(false);
  const { categories } = useSelector((state) => state.product);
  const user = getItem('user');

  const loadBanner = () => {
    axios
      .get(`${API_URL}/public/banner`, {
        headers: {
          'Punyakios-Api-Key': generatePasswordHash(),
        },
      })
      .then((res) => {
        setBanners(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    dispatch(getUserProfile());
    loadBanner();
    if (categories && categories.length < 1) {
      dispatch(loadProductCategory());
    }

    checkEmailVerivied(navigate);
  }, []);

  return (
    <div className="bg-[#f5f6fa]">
      <div className="  shadow-horizontal max-w-lg min-h-screen mx-auto my-0 bg-white">
        <div className="flex justify-between items-center p-4">
          <img className="h-6" src={require('../../assets/logo/punyakios.png')} alt="Punyakios image" />
          {getItem('access_token') && (
            <BellOutlined style={{ fontSize: 20 }} onClick={() => navigate('/notification')} />
          )}
        </div>

        {getItem('access_token') && (
          <div
            className="flex justify-between items-center shadow m-4 p-4 rounded-lg"
            onClick={() => navigate('/saldo')}
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faWallet} className="text-4xl" />
              <div className="ml-2">
                <p className="text-sm mb-0">Saldo </p>
                <div className="flex items-center">
                  <h1 className="text-md font-bold mb-0 mr-2">Rp. {formatNumber(user?.balance)}</h1>
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              icon={faChevronRight}
              onClick={() => navigate('/saldo')}
              className="text-xl cursor-pointer"
            />
          </div>
        )}

        {!getItem('access_token') && (
          <div className="shadow m-4 p-4 rounded-lg">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-xl font-bold">Selamat Datang</h1>
                <p className="text-sm">Silahkan login untuk melanjutkan</p>
              </div>
              <div className="w-18">
                <Button onClick={() => navigate('/auth/login')} title={'Masuk'} />
              </div>
            </div>
          </div>
        )}

        {/* product */}
        <div className=" mx-4  rounded-lg shadow">
          <div className="bg-white  rounded-lg">
            <Product data={(categories && categories) || []} homePage={true} />
          </div>
        </div>

        <div className=" mx-4 mt-4">
          <Banner data={banners} />
        </div>

        <div className="container mx-auto">
          <div className="mx-2 mt-2  bg-white  rounded-xl ">
            <h1 className="py-4 font-medium text-lg px-4 border-b ">Kenapa harus di Punyakios?</h1>
            <div className="overflow-x-auto mt-4">
              <div className="whitespace-nowrap">
                <div className="inline-block mr-4">
                  <div className="w-56 bg-white border border-gray-200 rounded-lg  py-12 mb-4 ml-4">
                    <div className="flex flex-col items-center pb-10">
                      <img
                        className="w-24 h-24 mb-3  p-4"
                        src={require('../../assets/icon/like.png')}
                        alt="Bonnie image"
                      />
                      <h5 className="mb-1 text-xl font-bold text-gray-900 ">Praktis</h5>
                      <span className="text-sm text-center text-gray-500  px-4">
                        Nikmati beragam transaksi <br /> dapat dilakukan melalui <br /> website atau aplikasi.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="inline-block mr-4">
                  <div className="w-56 bg-white border border-gray-200 rounded-lg  py-12 mb-4 px-2">
                    <div className="flex flex-col items-center pb-10">
                      <img
                        className="w-24 h-24 mb-3  p-4"
                        src={require('../../assets/icon/wifi.png')}
                        alt="Bonnie image"
                      />
                      <h5 className="mb-1 text-xl font-bold text-gray-900 ">Aman</h5>
                      <span className="text-sm text-center text-gray-500  px-4">
                        Kapanpun dan di mana pun
                        <br /> kamu memerlukannya, <br />
                        sistem kami Online 24 jam
                      </span>
                    </div>
                  </div>
                </div>
                <div className="inline-block mr-4">
                  <div className="w-56 bg-white border border-gray-200 rounded-lg  py-12 mb-4 px-2">
                    <div className="flex flex-col items-center pb-10">
                      <img
                        className="w-24 h-24 mb-3  p-4"
                        src={require('../../assets/icon/security.png')}
                        alt="Bonnie image"
                      />
                      <h5 className="mb-1 text-xl font-bold text-gray-900 ">Aman</h5>
                      <span className="text-sm text-center text-gray-500  px-4">
                        Kami memastikan semua <br /> informasi yang ada terjaga <br /> aman dalam setiap transaksi.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* google */}
        {/* <div className="container mx-auto mb-12 p-4">
          <div className="w-full p-4 text-center bg-white border border-gray-200 rounded-lg sm:p-8 ">
            <h5 className="mb-2 text-3xl font-bold text-gray-900 ">Download Aplikasi</h5>
            <p className="mb-5 text-base text-gray-500 sm:text-lg ">
              Nikmati kemudahan transaksi dimana saja dan kapan saja dari genggaman anda.
            </p>
            <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a
                href="#"
                className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-blue-700 dark:hover:bg-blue-600 dark:focus:ring-blue-700"
              >
                <svg
                  className="mr-3 w-7 h-7"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="google-play"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                  ></path>
                </svg>
                <div className="text-left">
                  <div className="mb-1 text-xs">Get in on</div>
                  <div className="-mt-1 font-sans text-sm font-semibold">Google Play</div>
                </div>
              </a>
            </div>
          </div>
        </div> */}

        {/* footer */}
        <div className="max-w-lg mx-auto fixed inset-x-0 bottom-0 border-t  bg-white ">
          <div className="mx-8 py-2 bg-white rounded">
            <div className="flex justify-between items-center">
              <div className="text-center">
                <FontAwesomeIcon icon={faHomeAlt} className="text-2xl" color="#ff851b" onClick={() => navigate('/')} />
                <p className="text-[#ff851b]">Home</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon icon={faClipboardList} className="text-2xl" onClick={() => navigate('/history')} />
                <p>History</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon icon={faUserAlt} className="text-2xl" onClick={() => navigate('/profile')} />
                <p>Profile</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeClient;

import { Button } from 'antd';
import React from 'react';
import { Modal } from '../modals/antd-modals';

const ModalDetail = ({ children, title = 'Konfirmasi Hapus', body = 'Yakin Ingin Hapus Data?' }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <div>
      <div onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
        {children}
      </div>

      <Modal
        visible={visible}
        title={title}
        footer={[
          <Button type="secondary" key="back" onClick={() => setVisible(false)}>
            Tutup
          </Button>,
        ]}
      >
        <p>{body}</p>
      </Modal>
    </div>
  );
};

export default ModalDetail;

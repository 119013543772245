import { faAddressBook, faArrowLeft, faChevronRight, faMessage, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuItem from '../../../Components/Profile/MenuItem';
import { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const LiveChat = () => {
  const navigate = useNavigate();
  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  return (
    <div className="bg-[#ecf0f1]">
      <div className=" bg-[#f5f6fa] shadow-horizontal max-w-lg mx-auto my-0">
        {/* header */}
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl absolute left-4 cursor-pointer"
                  onClick={() => {
                    navigate('/profile');
                  }}
                />
                <span className="capitalize text-md font-bold">Live Chat</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* content */}
        <div className="mt-0" style={{ marginTop: -20 }}>
          <iframe
            src="https://tawk.to/chat/64793bc1ad80445890f088ee/1h1sp2rdg"
            frameborder="0"
            className="w-full h-[90vh]"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default LiveChat;

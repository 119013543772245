import React from 'react';
import ProductItem from '../ProductItem';
import { useNavigate } from 'react-router-dom';
import { getProductVariable } from '../../Utils/helper';

const ProductProvider = ({ data, loading }) => {
  const navigate = useNavigate();
  return (
    <div className="mx-2 bg-white pt-2 rounded-xl ">
      <div className="flex justify-start items-start flex-wrap">
        {data.map((item, index) => {
          const provider_name = item.provider_name || item.category_name;
          const category_slug = item.category_slug;
          const provider_slug = item.provider_slug || item.category_slug;
          return (
            <ProductItem
              key={item.id}
              onClick={() => {
                navigate(`/transaksi/${item.category}/${provider_slug}`);
              }}
              label={provider_name}
              img={item.provider_image_url}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductProvider;

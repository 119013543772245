import { Col, Form, Input, Row, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/buttons/buttons';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import Heading from '../../../../components/heading/heading';
import { DataService } from '../../../../config/dataService/dataService';
import { getItem, setItem } from '../../../../utility/localStorageControl';
import { BasicFormWrapper } from '../../../styled';

function Profile() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: false,
  });

  const handleSubmit = (values) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(`/user/update/profile`, values)
      .then((res) => {
        setState((currentState) => ({ ...currentState, loading: false }));
        message.success('Profile Updated Successfully');
        setItem('user', res.data.user);
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  const user = getItem('user');
  return (
    <Cards
      title={
        <div className="setting-card-title">
          <Heading as="h4">Edit Profile</Heading>
          <span>Set Up Your Personal Information</span>
        </div>
      }
    >
      <Row justify="center">
        <Col xl={24} lg={24} xs={24}>
          <BasicFormWrapper>
            <Form form={form} name="editProfile" onFinish={handleSubmit}>
              <Form.Item
                name="name"
                initialValue={user?.name}
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                initialValue={user?.email}
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                initialValue={user?.phone}
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <div className="setting-form-actions">
                <Button
                  size="default"
                  type="primary"
                  onClick={() => {
                    if (state.loading) {
                      return false;
                    }

                    return form.submit();
                  }}
                >
                  {state.loading ? 'Loading...' : ' Update Profile'}
                </Button>
              </div>
            </Form>
          </BasicFormWrapper>
        </Col>
      </Row>
    </Cards>
  );
}

export default Profile;

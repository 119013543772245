import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Layout } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getItem } from '../../../../utility/localStorageControl';
import TopUpModalPayment from './Components/TopUpModalPayment';

const TopUpClient = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const user = getItem('user');
  const [amount, setAmount] = useState(0);

  return (
    <div className="bg-[#ecf0f1]">
      <div className=" bg-[#f5f6fa] shadow-horizontal max-w-lg min-h-screen mx-auto my-0">
        {/* header */}
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl absolute left-4 cursor-pointer"
                  onClick={() => {
                    navigate('/saldo');
                  }}
                />
                <span className="capitalize text-md font-bold">Top Up Saldo</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* content */}
        <div className="h-[80vh] mx-4">
          <div className=" p-4 bg-white rounded-lg">
            <div className="border-b">
              <Form form={form} layout="vertical">
                <Form.Item
                  required
                  name={'deposit_amount'}
                  rules={[
                    {
                      required: true,
                      message: 'Nominal harus diisi',
                    },
                    // min: 10000,
                    {
                      validator: async (_, value) => {
                        if (value < 10000) {
                          throw new Error('Nominal harus lebih dari Rp 10.000');
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder={'Input Nominal'}
                    type="number"
                    className="rounded-lg"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="max-w-lg mx-auto fixed inset-x-0 bottom-0 border-t  bg-white ">
          <div className=" inset-x-0 bottom-0 border-t">
            <div className="mx-4 py-2 bg-white rounded">
              <TopUpModalPayment amount={amount} disabled={!amount || amount < 10000} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpClient;

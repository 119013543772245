const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Provider',
    dataIndex: 'provider_name',
    key: 'provider_name',
  },
  {
    title: 'Kategori',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Logo',
    dataIndex: 'provider_image_url',
    key: 'provider_image_url',
    render: (text, record, index) => {
      return (
        <div>
          <img src={record.provider_image_url} alt="" style={{ height: 50 }} />
        </div>
      );
    },
  },
];

export { dataTableColumn };

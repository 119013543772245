const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Saldo',
    dataIndex: 'balance',
    key: 'balance',
  },
  {
    title: 'Status',
    dataIndex: 'status_reseller',
    key: 'status_reseller',
  },
];

export { dataTableColumn };

const actions = {
  UPDATE_USER: 'UPDATE_USER',

  updateUser: (data) => {
    return {
      type: actions.UPDATE_USER,
      data,
    };
  },
};

export default actions;

import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { Col, Form, Input, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '../../../components/modals/antd-modals';
import { DataService } from '../../../config/dataService/dataService';
const ModalForm = ({ refetch, initialValues = {}, url, type = 'pasca', levels = [] }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    if (type === 'pasca') {
      if (value.admin_fee < initialValues.vendor_admin_fee) {
        return message.error('Biaya Admin tidak boleh kurang dari harga vendor');
      }
    }

    if (value.product_price < initialValues.product_original_price) {
      return message.error('Harga tidak boleh kurang dari harga vendor');
    }
    DataService.post(url, { ...value, type, level_ids: levels.map((level) => level.id) })
      .then((res) => {
        form.resetFields();
        setIsModalOpen(false);
        refetch();
        message.success(res.data.message);
      })
      .catch((err) => {
        const { data } = err.response;
        message.error(data?.message);
      });
  };

  const labels = levels.map((level) => {
    return {
      [`level_name_${level.id}`]: level.level_name,
    };
  });

  const levelPrices = initialValues.level_prices.map((item) => {
    return {
      [`level_price_${item.level_price_id}`]: item.price,
      [`level_commision_${item.level_price_id}`]: item.commission,
    };
  });

  const labelLists = levelPrices.length > 0 ? Object.assign({}, ...levelPrices) : Object.assign({}, ...labels);

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
      ...labelLists,
    });
  }, [initialValues, labelLists]);

  return (
    <>
      <Link
        to="#"
        onClick={(e) => {
          showModal();
        }}
      >
        <span className="flex items-center">
          <UilEdit />
          <span>Edit</span>
        </span>
      </Link>
      <Modal
        title={'Edit Product'}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
        width={1000}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={handleSubmit}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={24}>
              <Form.Item label="Nama Produk" name="product_name">
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              {type === 'pasca' ? (
                <>
                  <Form.Item
                    label={`Biaya Admin Vendor`}
                    name="vendor_admin_fee"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Admin Fee!',
                      },
                    ]}
                  >
                    <Input type="number" size="small" style={{ paddingTop: 6, paddingBottom: 6 }} disabled />
                  </Form.Item>
                  {levels.map((level) => {
                    return (
                      <Form.Item label={`Biaya Admin  (${level.level_name})`} name={`level_price_${level.id}`}>
                        <Input
                          size="small"
                          style={{ paddingTop: 6, paddingBottom: 6 }}
                          onChange={(e) => {
                            const { value } = e.target;
                            const price = form.getFieldValue('admin_fee');
                            const commission = value - price;

                            form.setFieldsValue({
                              [`level_commision_${level.id}`]: commission > 0 ? commission : 0,
                            });
                          }}
                        />
                      </Form.Item>
                    );
                  })}
                </>
              ) : (
                <>
                  <Form.Item
                    label={`Harga Product Vendor`}
                    name="product_original_price"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Harga Product!',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      size="small"
                      defaultValue={'okee'}
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                      disabled
                    />
                  </Form.Item>
                  {levels.map((level) => {
                    return (
                      <Form.Item label={`Harga  (${level.level_name})`} name={`level_price_${level.id}`}>
                        <Input
                          size="small"
                          style={{ paddingTop: 6, paddingBottom: 6 }}
                          onChange={(e) => {
                            const { value } = e.target;
                            const price = form.getFieldValue('product_price');
                            const commission = value - price;

                            form.setFieldsValue({
                              [`level_commision_${level.id}`]: commission > 0 ? commission : 0,
                            });
                          }}
                        />
                      </Form.Item>
                    );
                  })}
                </>
              )}
            </Col>
            <Col xs={12}>
              {type === 'pasca' ? (
                <>
                  <Form.Item
                    label={`Biaya Admin `}
                    name="admin_fee"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Admin Fee!',
                      },
                    ]}
                  >
                    <Input type="number" size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
                  </Form.Item>
                  {levels.map((level) => {
                    return (
                      <Form.Item label={`Komisi  (${level.level_name})`} name={`level_commision_${level.id}`}>
                        <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} disabled />
                      </Form.Item>
                    );
                  })}
                </>
              ) : (
                <>
                  <Form.Item
                    label={`Harga Product `}
                    name="product_price"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Harga Product!',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      size="small"
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                      onChange={(e) => {
                        const { value } = e.target;
                        const price = parseInt(value);
                        form.setFieldsValue({
                          product_price: isNaN(price) ? 0 : price,
                        });
                      }}
                    />
                  </Form.Item>
                  {levels.map((level) => {
                    return (
                      <Form.Item label={`Komisi Harga  (${level.level_name})`} name={`level_commision_${level.id}`}>
                        <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} disabled />
                      </Form.Item>
                    );
                  })}
                </>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;

const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Bank',
    dataIndex: 'bank_name',
    key: 'bank_name',
  },
  {
    title: 'Image',
    dataIndex: 'bank_logo_url',
    key: 'bank_logo_url',
    render: (text, record, index) => {
      return (
        <div>
          <img src={record.bank_logo_url} alt="" style={{ height: 50 }} />
        </div>
      );
    },
  },
];

export { dataTableColumn };

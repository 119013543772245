import { RightOutlined } from "@ant-design/icons"
import { Tag } from "antd"

const dataColumns = [
  {
    title: " Produk",
    dataIndex: "produk",
    key: "produk",
    render: (produk) => (
      <div>
        {produk.map((item) => (
          <div>
            {item.label} : {item.value}
          </div>
        ))}
      </div>
    ),
  },

  {
    title: "Harga Produk",
    dataIndex: "harga_produk",
    key: "harga_produk",
    render: (harga_produk) => (
      <div>
        {harga_produk.map((item) => (
          <div>
            {item.label} : {item.value}
          </div>
        ))}
      </div>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => {
      if (text > 0) {
        return <Tag color="green">Tersedia</Tag>
      } else {
        return <Tag color="red">Tidak Tersedia</Tag>
      }
    },
  },
]

const sampleData = [
  {
    produk: [
      {
        label: "Kode",
        value: "PLS5",
      },
      {
        label: "Nama",
        value: "Pulsa 5.000",
      },
    ],
    harga_produk: [
      {
        label: "Reguler",
        value: "10000",
      },
      {
        label: "Reseller",
        value: "9000",
      },
    ],
    status: 1,
  },
  {
    produk: [
      {
        label: "Kode",
        value: "PLS5",
      },
      {
        label: "Nama",
        value: "Pulsa 5.000",
      },
    ],
    harga_produk: [
      {
        label: "Reguler",
        value: "10000",
      },
      {
        label: "Reseller",
        value: "9000",
      },
    ],
    status: 1,
  },
]

export { dataColumns, sampleData }

import { Cascader, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import { API_URL } from '../../Utils/Constant';
import { dataColumns, sampleData } from './config';
import { generatePasswordHash } from '../../../../utility/utility';

const PriceClient = () => {
  const [items, setItems] = useState([]);
  const [products, setProducts] = useState([]);

  const loadProducts = (product) => {
    axios
      .get(`${API_URL}/product/list/${product}`, {
        headers: {
          'Punyakios-Api-Key': generatePasswordHash(),
        },
      })
      .then((res) => {
        const { data } = res.data;

        const newData = data.map((item) => {
          const prices = item.level_prices.map((price) => {
            return {
              label: price.level_name,
              value: price.price,
            };
          });
          return {
            produk: [
              {
                label: 'Kode',
                value: item.product_sku,
              },
              {
                label: 'Nama',
                value: item.product_name,
              },
            ],
            harga_produk: prices,
            status: item.product_status,
          };
        });

        setProducts(newData);
      })
      .catch((err) => {});
  };

  const loadCategory = () => {
    axios
      .get(`${API_URL}/product/category`, {
        headers: {
          'Punyakios-Api-Key': generatePasswordHash(),
        },
      })
      .then((res) => {
        const { data } = res.data;
        const newData = data.map((item) => {
          return {
            value: item.category_name,
            label: item.category_name,
            children: item.providers.map((provider) => {
              return {
                value: provider.provider_name.replace(' ', '-'),
                label: provider.provider_name,
              };
            }),
          };
        });
        setItems(newData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadCategory();
    loadProducts('TELKOMSEL');
  }, []);

  const onChange = (value) => {
    loadProducts(value[1]);
  };

  // Just show the latest item.
  const displayRender = (labels) => labels[labels.length - 1];
  return (
    <div className="bg-[#f5f6fa] mx-auto  h-screen">
      <Navbar />
      <div className="mt-4 mx-4 p-4 bg-white max-w-3xl mx-auto">
        <Cascader
          options={items}
          expandTrigger="hover"
          displayRender={displayRender}
          onChange={onChange}
          defaultValue={['Pulsa', 'TELKOMSEL']}
        />

        <div className="mt-4">
          <Table columns={dataColumns} dataSource={products} pagination={false} />
        </div>
      </div>
    </div>
  );
};

export default PriceClient;

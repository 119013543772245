import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
const MenuItem = ({ label, icon, onClick, showBorder = true, fontClass, className, svg }) => {
  return (
    <div
      className={`flex items-center justify-between px-4 ${
        showBorder ? 'border-t' : ''
      } py-4 cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div className={`flex items-center ${className}`}>
        {svg ? icon : <FontAwesomeIcon icon={icon} />}

        <p className={`text-md font-bold ml-4 ${fontClass}`}>{label}</p>
      </div>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

export default MenuItem;

/* eslint-disable react/jsx-no-bind */
import { Col, Pagination, Row } from 'antd';
import React, { useEffect } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import DataTable from '../../../components/table/DataTable';
import { DataService } from '../../../config/dataService/dataService';
import ModalDeposit from '../../deposit/ModalDeposit';
import { BorderLessHeading, Main } from '../../styled';
import { dataTableColumn } from './config';
import { getUserProfile } from '../../../redux/user/actionCreator';
import { useDispatch } from 'react-redux';
import ModalApprove from './components/ModalApprove';
import { Dropdown } from '../../../components/dropdown/dropdown';
import UilEllipsisH from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import { Link } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

function TransactionProductPage() {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    data: [],
    loading: false,
    total: 0,
    currentPage: 1,
    search: null,
  });

  const PageRoutes = [
    {
      path: '/',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '#',
      breadcrumbName: 'Transaction',
    },
    {
      path: '/transaction/product/list',
      breadcrumbName: 'Product',
    },
  ];

  const loadData = (url = '/transaction/product/list', perpage = 10, params = { page: state.currentPage }) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(url, { perpage, ...params })
      .then((res) => {
        const { data, total, current_page } = res.data.data;
        setState((currentState) => ({
          ...currentState,
          loading: false,
          data,
          total,
          currentPage: current_page,
        }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    loadData();
    dispatch(getUserProfile());
  }, []);

  const onChange = (page, pageSize = 10) => {
    loadData(`/transaction/product/list/?page=${page}`, pageSize, {
      search: state.search,
      page,
    });
  };

  const { data, currentPage, total, search, loading } = state;
  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Table" routes={PageRoutes} />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <BorderLessHeading>
              <Cards title="List Transaction Product">
                <DataTable
                  filterOption
                  loading={loading}
                  tableData={data}
                  filterOnchange={(e) => setState((currentState) => ({ ...currentState, search: e }))}
                  onSearch={(e) => {
                    loadData(`/transaction/product/list`, 10, {
                      search: e || search,
                    });
                  }}
                  refetch={() => loadData()}
                  columns={[
                    ...dataTableColumn,
                    // {
                    //   title: 'Action',
                    //   dataIndex: 'action',
                    //   key: 'action',
                    //   render: (text, record) => {
                    //     if (record.status_payment == 0 && record.status == 2) {
                    //       return (
                    //         <div>
                    //           <Dropdown
                    //             content={
                    //               <>
                    //                 <ModalApprove
                    //                   initialValues={{ transaction_id: record.id }}
                    //                   url={`/transaction/product/approve`}
                    //                   refetch={() => loadData()}
                    //                 />
                    //               </>
                    //             }
                    //           >
                    //             <Link to="#">
                    //               <UilEllipsisH />
                    //             </Link>
                    //           </Dropdown>
                    //         </div>
                    //       );
                    //     }

                    //     if (record.status_payment == 3 || record.status == 3) {
                    //       return <CloseCircleFilled />;
                    //     }

                    //     return <CheckCircleFilled />;
                    //   },
                    // },
                  ]}
                />
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  total={total}
                  className="mt-4 text-center"
                  onChange={onChange}
                  pageSizeOptions={['10', '20', '50', '100', '200', '500']}
                />
              </Cards>
            </BorderLessHeading>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default TransactionProductPage;

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataService } from '../../../../config/dataService/dataService';
import { formatNumber } from '../../Utils/helper';
import { ProductItem } from '../../../deposit/deposit';
import Button from '../../Components/Button';

const HistoryDepositDetail = () => {
  const { reference } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    data: null,
    loading: false,
  });

  const getDetail = () => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.get(`/transaction/deposit/detail/${reference}`)
      .then((res) => {
        const { data } = res.data;
        setState((currentState) => ({ ...currentState, loading: false, data }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const { data, loading } = state;
  return (
    <div className="bg-[#ecf0f1]">
      <div className=" bg-[#f5f6fa] shadow-horizontal max-w-lg min-h-screen mx-auto my-0">
        {/* header */}
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl absolute left-4 cursor-pointer"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <span className="capitalize text-md font-bold">Detail Transaksi</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* content */}
        <div className={`mx-4 bg-white  rounded-lg mt-2`} key={data?.id}>
          <div className="flex justify-between items-center px-4 py-2">
            <div>
              <p className="mb-0 text-sm font-bold">{data?.trx_id}</p>
              <p className="mb-0 text-xs">{data?.created_payment}</p>
            </div>
            <Tag color={data?.status_color} className="mr-0">
              {data?.status_name}
            </Tag>
          </div>
          <div className="px-4 py-2">
            <ProductItem label="ID Transaksi" value={data?.trx_id} />
            <ProductItem label="Metode Pembayaran" value={data?.payment_name} />
            {!data?.payment && (
              <>
                <ProductItem label="Nominal Deposit" value={formatNumber(data?.deposit_amount, 'Rp ')} />
                <ProductItem label="Biaya Admin" value={formatNumber(data?.payment_fee, 'Rp ')} />
                <ProductItem
                  label={'Nominal Transfer'}
                  value={formatNumber(parseInt(data?.deposit_amount) + parseInt(data?.payment_fee), 'Rp ')}
                />
                <ProductItem label={'Nomor Virtual Account'} value={data?.paymentVaNumber} copyText />
              </>
            )}

            {data?.payment && (
              <>
                <ProductItem
                  label="Nominal Deposit"
                  value={formatNumber(data?.deposit_amount - data?.unique_code, 'Rp ')}
                />
                <ProductItem
                  label={'Jumlah Transfer'}
                  value={data?.deposit_amount}
                  originalValue={data?.deposit_amount}
                  copyText
                />
                <ProductItem label="Berita Transfer" value={data?.ref} copyText />
                <ProductItem label="Nomor Rekening Transfer" value={data?.payment?.bank_account_number} copyText />
                <ProductItem label="Nama Rekening Transfer" value={data?.payment?.bank_account_name} />
              </>
            )}
          </div>
        </div>

        {/* footer */}
        <div className="max-w-lg mx-auto fixed inset-x-0 bottom-0 border-t  bg-white ">
          <div className=" inset-x-0 bottom-0 border-t">
            <div className="mx-4 py-2 bg-white rounded">
              {!data?.payment && data?.status < 1 && (
                <Button onClick={() => window.open(data?.paymentUrl, '_blank')} title="Bayar Sekarang" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryDepositDetail;

import { faClipboardList, faHomeAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Tag } from 'antd';
import Pusher from 'pusher-js';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataService } from '../../../../config/dataService/dataService';
import { formatNumber } from '../../Utils/helper';
import Loading from '../../Components/Loading';
import { ReactComponent as EmptyStateTransaction } from '../../assets/ilustration/no-transaction.svg';
import { ReactComponent as NeedLogin } from '../../assets/ilustration/need-login.svg';
import Button from '../../Components/Button';
import { getItem } from '../../../../utility/localStorageControl';
import { useDispatch } from 'react-redux';
import { getUserProfile } from '../../../../redux/user/actionCreator';

const HistoryClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pusherChannel, setPusherChannel] = React.useState(null);
  const [state, setState] = React.useState({
    data: [],
    loading: false,
    total: 0,
    currentPage: 1,
    search: null,
  });

  const loadData = (url = '/transaction/product/list', perpage = 10, params = { page: state.currentPage }) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.post(url, { perpage, ...params })
      .then((res) => {
        const { data, total, current_page } = res.data.data;
        setState((currentState) => ({
          ...currentState,
          loading: false,
          data,
          total,
          currentPage: current_page,
        }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    loadData();

    // Pusher.logToConsole = true;
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    const channelPusher = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);
    setPusherChannel(channelPusher);
  }, []);

  useEffect(() => {
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind('transaction-payment-success');
      pusherChannel.bind('transaction-payment-success', function (data) {
        dispatch(getUserProfile());
        DataService.post(
          '/transaction/product/list',
          { perpage: 10 },
          {
            header: {
              Authorization: `Bearer ${getItem('access_token')}`,
            },
          },
        )
          .then((res) => {
            const { data, total, current_page } = res.data.data;
            setState((currentState) => ({
              ...currentState,
              loading: false,
              data,
              total,
              currentPage: current_page,
            }));
          })
          .catch((err) => {
            setState((currentState) => ({ ...currentState, loading: false }));
          });
      });
    }
  }, [pusherChannel]);

  const { data, currentPage, total, loading } = state;

  return (
    <div className="bg-[#f5f6fa]">
      <div className="  shadow-horizontal max-w-lg min-h-screen mx-auto my-0 bg-white">
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-center items-center   ">
                <span className="capitalize text-md font-bold">Daftar Transaksi</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* need login */}
        {!getItem('access_token') && (
          <div className="flex flex-col justify-center items-center h-96 mt-8">
            <NeedLogin className="w-[50%]" />
            <p className="mt-4 w-50 text-center text-md font-medium">Kamu Belum Masuk ke Akun</p>
            <p className="mt-4 w-96 text-center">
              Masuk ke akun kamu dulu yuk biar bisa lihat profilmu dan mengakses semua fitur menarik dari Punyakios
            </p>
            <div className="w-40 mt-8">
              <Button onClick={() => navigate('/auth/login')} title={'Masuk Sekarang'} />
            </div>
          </div>
        )}

        {getItem('access_token') && (
          <>
            {/* product loading */}
            {loading && (
              <div className="flex justify-center items-center h-[80vh]">
                <Loading />
              </div>
            )}

            {/* product list */}
            {!loading && (
              <div className="container mx-auto " style={{ paddingBottom: 100 }}>
                {data.map((item, index) => {
                  return (
                    <div
                      className={`mx-4 bg-white  shadow  rounded-lg mt-2 cursor-pointer`}
                      onClick={() => navigate('/transaksi/detail/' + item.ref)}
                      key={item?.id}
                    >
                      <div className="flex justify-between items-center px-4 py-2">
                        <div>
                          <p className="mb-0 text-sm font-bold">{item.transaction_id}</p>
                          <p className="mb-0 text-xs">{item.transaction_date}</p>
                        </div>
                        <Tag color={item.status_color} className="mr-0">
                          {item.status_name}
                        </Tag>
                      </div>
                      <div className="flex justify-between items-center px-4 py-2 border-t">
                        <p className="mb-0 text-sm font-bold">{item?.product?.product_original_name || '-'}</p>
                        <p className="mb-0 text-sm font-bold">{formatNumber(item.price, 'Rp ')}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {/* empty state */}
            {!loading && data && data.length < 1 && (
              <div className="flex flex-col justify-center items-center h-[80vh]">
                <EmptyStateTransaction className="w-[80%]" />
                <p className="mt-4 w-60 text-center">Sayangnya, kamu belum pernah beli apa-apa di Punyakios :</p>
                <div className="w-40 mt-8">
                  <Button onClick={() => navigate('/product/lainnya')} title={'Beli Sekarang'} />
                </div>
              </div>
            )}
          </>
        )}

        {/* footer */}
        <div className="max-w-lg mx-auto fixed inset-x-0 bottom-0 border-t  bg-white ">
          <div className="mx-8 py-2 bg-white rounded">
            <div className="flex justify-between items-center">
              <div className="text-center">
                <FontAwesomeIcon icon={faHomeAlt} className="text-2xl" onClick={() => navigate('/')} />
                <p>Home</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faClipboardList}
                  className="text-2xl"
                  color="#ff851b"
                  onClick={() => navigate('/history')}
                />
                <p className="text-[#ff851b]">History</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon icon={faUserAlt} className="text-2xl" onClick={() => navigate('/profile')} />
                <p>Profile</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryClient;

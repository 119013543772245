import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

const Button = ({ title = 'Button', onClick, disabled = false, loading = false, icon, color = 'blue' }) => {
  if (disabled) {
    return (
      <button
        type="button"
        className="w-full text-white bg-gray-100 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-100 dark:hover:bg-gray-100 focus:outline-none dark:focus:ring-gray-100 cursor-not-allowed:focus"
      >
        <span>{icon}</span>
        <span className={icon ? 'ml-4' : ''}>{title}</span>
      </button>
    );
  }

  if (loading) {
    return (
      <button
        type="button"
        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        <LoadingOutlined />
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      type="button"
      className={`w-full flex items-center justify-center text-white bg-${color}-700 hover:bg-${color}-800 focus:ring-4 focus:ring-${color}-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 focus:outline-none `}
    >
      <span>{icon}</span>
      <span className={icon ? 'ml-4' : ''}>{title}</span>
    </button>
  );
};

export default Button;

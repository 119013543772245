import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Pages from './pages';
import MenuPage from '../../container/setting/menu';
import withAdminLayout from '../../layout/withAdminLayout';
import ErrorPage from '../../container/pages/404';
import RolePage from '../../container/setting/role';
import ProductList from '../../container/product/ProductList';
import LevelPricePage from '../../container/master/level-price';
import BannerPage from '../../container/master/banner';
import DepositPage from '../../container/deposit/deposit';
import CategoryPage from '../../container/master/category';
import ProviderPage from '../../container/master/provider';
import TransactionDepositPage from '../../container/transaction/deposit';
import ResellerPage from '../../container/reseller';
import TransactionProductPage from '../../container/transaction/product';
import SettingGeneralPage from '../../container/setting/general';
import PaymentMethodPage from '../../container/master/payment-method';
import TransactionDetailPage from '../../container/transaction/product/detail';
import Settings from '../../container/profile/settings/Settings';
import Notifications from '../../container/profile/notifications/Index';
import TransactionTopUpPage from '../../container/transaction/deposit/topup';
import Dashboard from '../../container/pages/Dashboard';
import UserListPage from '../../container/user';

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/setting/menu" element={<MenuPage />} />
        <Route path="/setting/role" element={<RolePage />} />
        <Route path="/setting/general" element={<SettingGeneralPage />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/notification" element={<Notifications />} />

        {/* master */}
        <Route path="/master/level-price" element={<LevelPricePage />} />
        <Route path="/master/banner" element={<BannerPage />} />
        <Route path="/master/category" element={<CategoryPage />} />
        <Route path="/master/provider" element={<ProviderPage />} />
        <Route path="/master/payment-method" element={<PaymentMethodPage />} />

        {/* product */}
        <Route path="/product/prabayar" element={<ProductList type="prepaid" />} />
        <Route path="/product/pascabayar" element={<ProductList type="pasca" />} />
        <Route path="*" element={<ErrorPage />} />

        <Route path="deposit/:reference" element={<DepositPage type={'deposit'} />} />
        <Route path="transaction/:reference" element={<DepositPage type={'product'} />} />
        <Route path="transaction/detail/:reference" element={<TransactionDetailPage type={'product'} />} />

        {/* transaction */}
        <Route path="/deposit/history" element={<TransactionDepositPage />} />
        <Route path="/deposit/topup" element={<TransactionTopUpPage />} />
        <Route path="/transaction/product" element={<TransactionProductPage />} />

        {/* reseller */}
        <Route path="/reseller" element={<ResellerPage />} />
        <Route path="/user-list" element={<UserListPage />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);

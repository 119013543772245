const actions = {
  // category actions
  LOAD_PRODUCT_CATEGORY_BEGIN: 'LOAD_PRODUCT_CATEGORY_BEGIN',
  LOAD_PRODUCT_CATEGORY_SUCCESS: 'LOAD_PRODUCT_CATEGORY_SUCCESS',
  LOAD_PRODUCT_CATEGORY_ERR: 'LOAD_PRODUCT_CATEGORY_ERR',

  // providers
  LOAD_PRODUCT_PROVIDER_BEGIN: 'LOAD_PRODUCT_PROVIDER_BEGIN',
  LOAD_PRODUCT_PROVIDER_SUCCESS: 'LOAD_PRODUCT_PROVIDER_SUCCESS',
  LOAD_PRODUCT_PROVIDER_ERR: 'LOAD_PRODUCT_PROVIDER_ERR',

  loadProductCategoryBegin: () => {
    return {
      type: actions.LOAD_PRODUCT_CATEGORY_BEGIN,
    };
  },

  loadProductCategorySuccess: (data) => {
    return {
      type: actions.LOAD_PRODUCT_CATEGORY_SUCCESS,
      data,
    };
  },

  loadProductCategoryErr: (err) => {
    return {
      type: actions.LOAD_PRODUCT_CATEGORY_ERR,
      err,
    };
  },

  // PROVIDER
  loadProductProviderBegin: () => {
    return {
      type: actions.LOAD_PRODUCT_PROVIDER_BEGIN,
    };
  },

  loadProductProviderSuccess: (data) => {
    return {
      type: actions.LOAD_PRODUCT_PROVIDER_SUCCESS,
      data,
    };
  },

  loadProductProviderErr: (err) => {
    return {
      type: actions.LOAD_PRODUCT_PROVIDER_ERR,
      err,
    };
  },
};

export default actions;

import { PlusOutlined } from '@ant-design/icons';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { Col, Form, Input, message, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/buttons/buttons';
import { Modal } from '../../../../components/modals/antd-modals';
import { DataService } from '../../../../config/dataService/dataService';

const ModalForm = ({ refetch, initialValues = {}, update = false, url, roles = [] }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    const newValues = { ...initialValues };

    DataService.post(url, { ...newValues, ...value })
      .then((res) => {
        form.resetFields();
        setIsModalOpen(false);
        refetch();
        message.success(res.data.message);
      })
      .catch((err) => {
        const { data } = err.response;
        message.error(data?.message);
      });
  };

  return (
    <>
      {update ? (
        <Link
          to="#"
          onClick={(e) => {
            e?.stopPropagation();
            showModal();
          }}
        >
          <span>
            <UilEdit />
            <span>Edit</span>
          </span>
        </Link>
      ) : (
        <Button onClick={() => showModal()} type="primary">
          <PlusOutlined color="#fff" />
          <span className="ml-2">Data Baru</span>
        </Button>
      )}
      <Modal
        title={update ? 'Edit Level' : 'Tambah Level Baru'}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
        width={1000}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{
            ...initialValues,
          }}
          onFinish={handleSubmit}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={12}>
              <Form.Item label="Nama Level" name="level_name">
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Role"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Role!',
                  },
                ]}
              >
                <Select allowClear className="w-full mb-2" placeholder="Pilih Role">
                  {roles.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.role_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Profile from '../../../../profile/settings/overview/Profile';

const UpdateProfile = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#ecf0f1]">
      <div className=" bg-[#f5f6fa] shadow-horizontal max-w-lg min-h-screen mx-auto my-0">
        {/* header */}
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl absolute left-4 cursor-pointer"
                  onClick={() => {
                    navigate('/profile');
                  }}
                />
                <span className="capitalize text-md font-bold">Edit Profile</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* content */}
        <div className="mx-4">
          <Profile />
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;

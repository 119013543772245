import React from 'react';
import Pulsa from '../../assets/icon/pulsa.svg';

const ProductItem = ({ img, label, onClick, homePage }) => {
  return (
    <div className={`w-1/4  text-center ${homePage ? 'mb-2' : 'mb-12'} cursor-pointer`} onClick={onClick}>
      <div
        className={`${
          img ? 'bg-white' : 'bg-gray-300'
        } w-20 h-20 flex justify-center items-center rounded-xl shadow mx-auto p-2`}
      >
        {img ? <img src={img} alt={label} className=" rounded-xl" /> : <img src={Pulsa} alt={label} />}
      </div>
      <p className="mt-2 font-medium text-xs">{label}</p>
    </div>
  );
};

export default ProductItem;

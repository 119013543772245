import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilBell from '@iconscout/react-unicons/icons/uil-bell';
import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logOut } from '../../../redux/authentication/actionCreator';
import { getItem } from '../../../utility/localStorageControl';
import { formatNumber } from '../../../utility/utility';
import { Dropdown } from '../../dropdown/dropdown';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './Message';
import Notification from './Notification';
import Search from './Search';
import Settings from './settings';
import { HomeOutlined } from '@ant-design/icons';
import UilEllipsisH from '@iconscout/react-unicons/icons/uil-ellipsis-h';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    flag: 'en',
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };

  const user = getItem('user');

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon" style={{ width: 250 }}>
        <figure
          className="user-dropdwon__info"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <figcaption>
            <Heading as="h5">Saldo Active</Heading>
            <p>Rp {formatNumber(user.balance)}</p>
          </figcaption>
          {/* <ModalDeposit /> */}
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="settings/profile">
              <UilUser /> Profile
            </Link>
          </li>
          <li>
            <Link to="/">
              <UilEllipsisH /> Home
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Notification />

      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src={user?.profile_image_url} />
            <span className="ninjadash-nav-actions__author--name">{user?.name}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;

import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import React, { lazy, useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import ClientFrontendRoutes from './routes/client';
import './static/css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { getItem } from './utility/localStorageControl';
import { getUserProfile } from './redux/user/actionCreator';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const dispatch = useDispatch();
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });
  const user = getItem('user');

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} style={{ zIndex: 9999999 }} />
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <SimpleReactLightbox>
          <Router basename={process.env.PUBLIC_URL}>
            {!isLoggedIn ? (
              <Routes>
                <Route path="/*" element={<ClientFrontendRoutes />} />
                <Route path="/auth/*" element={<Auth />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/*" element={<ClientFrontendRoutes />} />
                <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            )}
            {isLoggedIn && !user.is_email_verified && (
              <Routes>
                {/* <Route path="/" element={<Navigate to="/akun/email-verification" />} /> */}
                {/* <Route path="/*" element={<ClientFrontendRoutes />} /> */}
              </Routes>
            )}
          </Router>
        </SimpleReactLightbox>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;

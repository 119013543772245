import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout } from 'antd';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { generatePasswordHash } from '../../../../utility/utility';
import Product from '../../Components/Product';
import ProductProvider from '../../Components/Product/ProductProvider';
import { API_URL } from '../../Utils/Constant';

const ProductDetail = () => {
  const { slug } = useParams();
  const isAll = slug === 'lainnya';
  const navigate = useNavigate();
  const [providers, setProviders] = React.useState([]);
  const { categories } = useSelector((state) => state.product);

  const loadProvider = () => {
    axios
      .get(`${API_URL}/product/provider/${slug}`, {
        headers: {
          'Punyakios-Api-Key': generatePasswordHash(),
        },
      })
      .then((res) => {
        setProviders(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (slug !== 'lainnya') {
      loadProvider();
    }
  }, [slug]);

  return (
    <div className="bg-[#f5f6fa]">
      <div className="  shadow-horizontal max-w-lg min-h-screen mx-auto my-0 bg-white">
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-start items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl cursor-pointer"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <span className="capitalize ml-2 text-md font-bold">{slug}</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* product */}
        <div className="container mx-auto mt-8 ">
          <div className="mx-4  bg-white  rounded-lg">
            {isAll ? <Product data={(categories && categories) || []} detail /> : <ProductProvider data={providers} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;

import { RightCircleOutlined } from "@ant-design/icons"
import React from "react"

const PaymentMethodItem = ({
  image,
  title,
  onClick,
  selected = false,
  disabled = false,
}) => {
  if (disabled) {
    return (
      <div
        className="border bg-gray-100 p-3 rounded-lg flex justify-between items-center mb-4 cursor-not-allowed"
        onClick={onClick}
      >
        <div className="flex justify-start items-center">
          <img src={image} alt="" className="h-5" />
          <p className="mb-0 ml-4">{title}</p>
        </div>
        <RightCircleOutlined />
      </div>
    )
  }

  if (selected) {
    return (
      <div
        className="border border-blue-900 p-3 rounded-lg flex justify-between items-center mb-4"
        onClick={onClick}
      >
        <div className="flex justify-start items-center">
          <img src={image} alt="" className="h-5" />
          <p className="mb-0 ml-4">{title}</p>
        </div>
        <RightCircleOutlined />
      </div>
    )
  }

  return (
    <div
      className="border p-3 rounded-lg flex justify-between items-center mb-4 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex justify-start items-center">
        <img src={image} alt="" className="h-5" />
        <p className="mb-0 ml-4">{title}</p>
      </div>
      <RightCircleOutlined />
    </div>
  )
}

export default PaymentMethodItem

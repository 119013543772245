import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ActivityContent from '../../../profile/notifications/overview/ActivityContent';
import { checkEmailVerivied } from '../../Utils/helper';

const Notification = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkEmailVerivied(navigate);
  }, []);

  return (
    <div className="bg-[#f5f6fa]">
      <div className="  shadow-horizontal max-w-lg min-h-screen mx-auto my-0 bg-white">
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-start items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl cursor-pointer"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <span className="capitalize ml-2 text-md font-bold">Notification</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* product */}
        <div className="container mx-auto mt-8 ">
          <div className="mx-4  bg-white  rounded-lg">
            <ActivityContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;

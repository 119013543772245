import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../utility/utility';

const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'TRX ID',
    dataIndex: 'trx_id',
    key: 'trx_id',
    render: (text, record, index) => {
      return <Link to={`/admin/deposit/${record.ref}`}>{text}</Link>;
    },
  },
  {
    title: 'Member',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: 'Amount',
    dataIndex: 'deposit_amount',
    key: 'deposit_amount',
    render: (text, record, index) => {
      return formatNumber(text, 'Rp. ');
    },
  },
  {
    title: 'Payment Method',
    dataIndex: 'payment_name',
    key: 'payment_name',
  },
  {
    title: 'Status',
    dataIndex: 'status_name',
    key: 'status_name',
    render: (text, record, index) => {
      return <Tag color={record.status_color}>{text}</Tag>;
    },
  },
];

export { dataTableColumn };

import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Col, Form, message, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '../../../components/modals/antd-modals';
import { DataService } from '../../../config/dataService/dataService';

const ModalForm = ({ refetch, initialValues = {}, url }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    setLoading(true);

    DataService.post(url, { ...value, ...initialValues })
      .then((res) => {
        setLoading(false);
        form.resetFields();
        setIsModalOpen(false);
        refetch();
        Cookies.set('user', JSON.stringify(res.data.data));
        message.success(res.data.message);
      })
      .catch((err) => {
        const { data } = err.response;
        setLoading(false);
        message.error(data?.message);
      });
  };

  return (
    <>
      <Link
        to="#"
        onClick={(e) => {
          e?.stopPropagation();
          showModal();
        }}
      >
        <span className="flex flex-row items-center">
          <UilTimes />
          <span>Reject</span>
        </span>
      </Link>
      <Modal
        title={'Reject Request Reseller'}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
        width={1000}
        confirmLoading={loading}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{
            ...initialValues,
          }}
          onFinish={handleSubmit}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={24}>
              <Form.Item label="Alasan Reject" name="reject_message">
                <TextArea size="small" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;

import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import BlankPage from '../container/pages/BlankPage';
import AuthLayout from '../container/profile/authentication/Index';
import ResetPassword from '../container/profile/authentication/overview/ResetPassword';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));

const AuthRoot = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="forgotPassword" element={<ForgotPass />} />
      <Route path="register" element={<SignUp />} />
      <Route path="reset-password/:token/:email" element={<ResetPassword />} />
      <Route path="force-login" element={<Login />} />
      <Route path="force-logout" element={<BlankPage />} />
      <Route path="*" element={<AuthRoot />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Input, message, Row } from 'antd';
import React, { useState } from 'react';
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { DataService } from '../../../config/dataService/dataService';
const ModalBulkUpdate = ({ refetch, url, type = 'pasca', levels = [] }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    setLoadingSync(true);
    DataService.post(url, { ...value, product_type: type })
      .then((res) => {
        form.resetFields();
        setIsModalOpen(false);
        refetch();
        message.success(res.data.message);
        setLoadingSync(false);
      })
      .catch((err) => {
        const { data } = err.response;
        message.error(data?.message);
        setLoadingSync(false);
      });
  };

  return (
    <>
      <div className="mr-4">
        <Button
          onClick={(e) => {
            showModal();
          }}
          type="primary"
        >
          <FontAwesomeIcon icon={faEdit} />
          <span className="ml-2">Update Bulk</span>
        </Button>
      </div>

      <Modal
        title={'Update Bulk Price'}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
        width={1000}
        loading={loadingSync}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={handleSubmit}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={24}>
              <Form.Item
                label={`Harga Product Markup`}
                name="product_price"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Harga Product!',
                  },
                ]}
              >
                <Input type="number" size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
              {levels.map((level) => {
                return (
                  <Form.Item label={`Harga  (${level.level_name})  Markup`} name={`level_price_${level.id}`}>
                    <Input
                      size="small"
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                      onChange={(e) => {
                        const { value } = e.target;
                        const price = form.getFieldValue('product_price');
                        const commission = value - price;

                        form.setFieldsValue({
                          [`level_commision_${level.id}`]: commission > 0 ? commission : 0,
                        });
                      }}
                    />
                  </Form.Item>
                );
              })}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalBulkUpdate;

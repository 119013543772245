import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../utility/utility';

const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'TRX ID',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
    render: (text, record, index) => {
      return (
        <Link to={`/admin/transaction/detail/${record.ref}`} style={{ color: '#0097e6' }}>
          {text}
        </Link>
      );
    },
  },
  {
    title: 'User',
    dataIndex: 'member_name',
    key: 'member_name',
  },
  {
    title: 'Amount',
    dataIndex: 'price',
    key: 'price',
    render: (text, record, index) => {
      return formatNumber(text, 'Rp. ');
    },
  },
  {
    title: 'Payment Method',
    dataIndex: 'payment_name',
    key: 'payment_name',
  },
  {
    title: 'Status Payment',
    dataIndex: 'status_product_name',
    key: 'status_product_name',
    render: (text, record, index) => {
      return <Tag color={record.status_product_color}>{text}</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status_name',
    key: 'status_name',
    render: (text, record, index) => {
      return <Tag color={record.status_color}>{text}</Tag>;
    },
  },
];

export { dataTableColumn };

/* eslint-disable react/jsx-no-bind */
import { Col, Row, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { DataService } from '../../../config/dataService/dataService';
import { BorderLessHeading, Main } from '../../styled';

function TransactionDetailPage() {
  const { reference } = useParams();
  const [state, setState] = React.useState({
    data: null,
    loading: false,
  });

  const PageRoutes = [
    {
      path: '/',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '/transaction/product/list',
      breadcrumbName: 'Transaction',
    },
    {
      path: '/transaction/detail/' + reference,
      breadcrumbName: reference,
    },
  ];

  const getDetail = () => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.get(`/transaction/product/detail/${reference}`)
      .then((res) => {
        const { data } = res.data;
        setState((currentState) => ({ ...currentState, loading: false, data }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const { data, loading } = state;
  const detailItem = data?.response_data ? JSON.parse(data?.response_data) : {};
  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="" routes={PageRoutes} />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <BorderLessHeading>
              <Cards title={data?.transaction_id}>
                <ProductItem label="Jenis Produk" value={data?.product?.product_category} />
                <ProductItem label="Provider" value={data?.product?.product_brand} />
                <ProductItem label="Produk" value={data?.product?.product_original_name} />
                <ProductItem label={detailItem?.label} value={data?.customer_no} />
                <ProductItem label="Nominal" value={data?.price} />
                <ProductItem label="Pembayaran" value={data?.payment_name} />
                <ProductItem label="Tanggal Pembelian" value={data?.transaction_date} />
                <ProductItem
                  label="Status Pembayaran"
                  value={
                    <Tag color={data?.status_product_color} style={{ color: '#fff' }}>
                      {data?.status_product_name}
                    </Tag>
                  }
                />
                <ProductItem
                  label="Status Pengisian"
                  value={
                    <Tag color={data?.status_color} style={{ color: '#fff' }}>
                      {data?.status_name}
                    </Tag>
                  }
                />
                {data?.serial_number && <ProductItem label="SN" value={data?.serial_number} />}
              </Cards>
            </BorderLessHeading>
          </Col>
        </Row>
      </Main>
    </>
  );
}

const ProductItem = ({ classNameValue, classNameLabel, label = 'Label', value = 'Value' }) => {
  return (
    <div className="flex flex-row justify-between items-center mb-2">
      <p className={classNameLabel}>
        <strong>{label}</strong>
      </p>
      <p className={classNameValue}>{value}</p>
    </div>
  );
};

export default TransactionDetailPage;

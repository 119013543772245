import {
  faClipboardList,
  faEdit,
  faHomeAlt,
  faKey,
  faPaste,
  faPhoneAlt,
  faShieldCat,
  faUserAlt,
  faUserFriends,
  faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UilCamera from '@iconscout/react-unicons/icons/uil-camera';
import { Layout, Modal, Result, Upload, message } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataService } from '../../../../config/dataService/dataService';
import { getItem, setItem } from '../../../../utility/localStorageControl';
import { getBase64 } from '../../../../utility/utility';
import MenuItem from '../../Components/Profile/MenuItem';
import Button from '../../Components/Button';
import Cookies from 'js-cookie';
import { logOut } from '../../../../redux/authentication/actionCreator';
import { useDispatch } from 'react-redux';

const ProfileClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getItem('user');
  // state image
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [modalLogout, setModalLogout] = React.useState(false);

  const handleSubmit = (file) => {
    setLoading(true);
    let formData = new FormData();

    formData.append('profile_image', file);

    DataService.post(`/user/update/profile-photo`, formData)
      .then((res) => {
        setLoading(false);
        message.success('Profile Updated Successfully');
        setItem('user', res.data.user);
      })
      .catch((err) => {
        message.error('Something went wrong');
        setLoading(false);
      });
  };

  const handleChange = ({ fileList }) => {
    const list = fileList.pop();
    setTimeout(() => {
      getBase64(list.originFileObj, (url) => {
        setImageUrl(url);
      });
      handleSubmit(list.originFileObj);
    }, 1000);
  };

  const handleLogout = () => {
    message.success('Berhasil Logout');
    Cookies.remove('access_token');
    Cookies.remove('user');
    Cookies.remove('role');
    Cookies.remove('logedIn');
    return dispatch(logOut(() => navigate('/')));
  };

  return (
    <div className="bg-[#f5f6fa]">
      <div className="  shadow-horizontal max-w-lg min-h-screen mx-auto my-0 bg-white">
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-center items-center   ">
                <span className="capitalize text-md font-bold">Profile</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* content */}
        <div className=" mt-6 border rounded-[20px] shadow-md py-4 mx-4">
          <div className="px-4 flex justify-start items-center">
            {/* profile image user */}
            <div className="max-w-lg">
              {/* change photo */}
              {getItem('access_token') && (
                <div className="absolute ml-14 bg-white shadow rounded-full w-7 h-7 flex justify-center items-center">
                  <Upload
                    name="profile_image"
                    showUploadList={false}
                    multiple={false}
                    beforeUpload={() => false}
                    onChange={(e) => handleChange(e)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Upload>
                </div>
              )}
              {user?.profile_image_url ? (
                <div className="w-20 h-20 mr-4 overflow-hidden bg-transparent rounded-full border">
                  <img src={user?.profile_image_url} className="object-cover h-full mx-auto" alt="profile" />
                </div>
              ) : (
                <div className="w-20 h-20 mr-4 overflow-hidden bg-transparent rounded-full">
                  <img
                    src={'https://i.ibb.co/8j93TST/avatar.jpg'}
                    className="object-cover h-full mx-auto"
                    alt="profile"
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <text className="sm:text-base text-sm capitalize">{user?.name || 'Kamu belum masuk ke akun'}</text>
              <text className="text-[#FFC120] text-xs font-medium">
                {user?.phone || 'Masuk ke akun kamu dulu yuk biar bisa transaksi'}
              </text>
            </div>
          </div>
          {/* <img className="w-full" src={require('../Assets/Banner/AkunBanner.png')} alt="" /> */}
          <div className="flex items-center justify-between px-4 mt-2 border-t pt-4">
            {user?.email ? (
              <div className="flex flex-col justify-between">
                <text className="text-[10px] text-grayText">Alamat Email</text>
                <text className=" text-xs">{user?.email}</text>
              </div>
            ) : (
              <span></span>
            )}

            {getItem('access_token') ? (
              <button className="hover:bg-[#e67e22]/90 active:bg-[#e67e22] border-[#e67e22] drop-shadow-xl bg-[#e67e22] flex items-center h-8 px-4 ml-2 text-xs text-white duration-300 ease-in-out delay-100 border-2 rounded-full">
                Member
              </button>
            ) : (
              <div className="flex items-center">
                <button
                  onClick={() => navigate('/auth/register')}
                  className="hover:bg-[#e67e22]/90 active:bg-white border-[#e67e22] drop-shadow-xl bg-white flex items-center h-8 px-4 ml-2 text-xs text-[#e67e22] hover:text-white  duration-300 ease-in-out delay-100 border-2 rounded-full"
                >
                  Daftar
                </button>
                <button
                  onClick={() => navigate('/auth/login')}
                  className="hover:bg-white active:bg-[#e67e22] border-[#e67e22] hover:text-[#e67e22] drop-shadow-xl bg-[#e67e22] flex items-center h-8 px-4 ml-2 text-xs text-white duration-300 ease-in-out delay-100 border-2 rounded-full"
                >
                  Masuk
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="mt-8 mx-4" style={{ paddingBottom: 100 }}>
          {/* Pengaturan Akun */}
          {getItem('access_token') && (
            <div>
              <p className="font-bold text-lg py-2">Akun</p>
              <MenuItem
                label={'Edit Profile'}
                icon={faUserGear}
                showBorder={false}
                onClick={() => {
                  navigate('/setting/update-profile');
                }}
              />
              <MenuItem
                label={'Ubah Kata Sandi'}
                icon={faKey}
                onClick={() => {
                  navigate('/setting/update-password');
                }}
              />
            </div>
          )}

          {/* Informasi */}
          <div>
            <p className="font-bold text-lg py-2">Informasi</p>
            <MenuItem
              label={'Tentang Kami'}
              icon={faUserFriends}
              showBorder={false}
              onClick={() => navigate('/info/tentang-kami')}
            />
            <MenuItem label={'Customer Service'} icon={faPhoneAlt} onClick={() => navigate('/info/bantuan')} />
            <MenuItem label={'Ketentuan Layanan'} icon={faShieldCat} />
            <MenuItem label={'Kebijakan Privasi'} icon={faPaste} onClick={() => navigate('/info/kebijakan-privasi')} />
          </div>

          {/* Keluar */}
          {getItem('access_token') && (
            <div className="mt-4">
              <Button onClick={() => setModalLogout(true)} title={'Keluar'} color="blue" />
              <Modal
                title={`Konfirmas Keluar`}
                open={modalLogout}
                onOk={() => setModalLogout(false)}
                onCancel={() => setModalLogout(false)}
                footer={null}
              >
                <Result
                  status="warning"
                  title="Konfirmas Keluar"
                  subTitle="Apakah kamu yakin ingin keluar dari akun ini?"
                  extra={[
                    <div className="flex items-start justify-center">
                      <div className="w-20">
                        <Button
                          type="primary"
                          key="console"
                          title="Batal"
                          onClick={() => {
                            setModalLogout(false);
                          }}
                        />
                      </div>
                      ,
                      <div className="w-20">
                        <Button
                          color="blue"
                          type="danger"
                          key="console"
                          title="Keluar"
                          onClick={() => {
                            handleLogout();
                          }}
                        />
                      </div>
                      ,
                    </div>,
                  ]}
                />
              </Modal>
            </div>
          )}
        </div>
        {/* end content */}

        {/* footer */}
        <div className="max-w-lg mx-auto fixed inset-x-0 bottom-0 border-t  bg-white ">
          <div className="mx-8 py-2 bg-white rounded">
            <div className="flex justify-between items-center">
              <div className="text-center">
                <FontAwesomeIcon icon={faHomeAlt} className="text-2xl" onClick={() => navigate('/')} />
                <p>Home</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon icon={faClipboardList} className="text-2xl" onClick={() => navigate('/history')} />
                <p>History</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faUserAlt}
                  color="#ff851b"
                  className="text-2xl"
                  onClick={() => navigate('/profile')}
                />
                <p className="text-[#ff851b]">Profile</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileClient;

import Cookies from 'js-cookie';

const getProductVariable = (type = 'Pulsa') => {
  switch (type) {
    case 'PULSA':
      return 'pulsa';
    case 'DATA':
      return 'data';
    case 'GAMES':
      return 'game';
    case 'TV':
      return 'tv';
    case 'STREAMING':
      return 'streaming';
    case 'PERTAGAS':
      return 'pertagas';
    case 'VOUCHER':
      return 'voucher';
    case 'INTERNET PASCABAYAR':
      return 'internet';
    case 'TV PASCABAYAR':
      return 'tvkabel';
    case 'HP PASCABAYAR':
      return 'hp-pascabayar';
    case 'MULTIFINANCE':
      return 'multifinance';
    case 'PBB':
      return 'pbb';
    case 'GAS NEGARA':
      return 'gas-negara';
    case 'BPJS KETENAGAKERJAAN':
      return 'bpjs-ketenagakerjaan';
    case 'PDAM':
      return 'pdam';
    case 'PLN PASCABAYAR':
      return 'tagihan-pln';
    case 'BPJS KESEHATAN':
      return 'bpjs-kesehatan';
    case 'PLN':
      return 'token-listrik';
    case 'E-MONEY':
      return 'e-wallet';
    case 'PAKET SMS & TELPON':
      return 'sms-telpon';
    case 'AKTIVASI VOUCHER':
      return 'aktivasi-voucher';
    case 'MASA AKTIF':
      return 'masa-aktif';
    case 'AKTIVASI PERDANA':
      return 'aktivasi-perdana';
    case 'BUNDLING':
      return 'bundling';

    default:
      return 'pulsa';
  }
};

// const getItem = (key, parse = false) => {
//   if (parse) {
//     return JSON.parse(localStorage.getItem(key))
//   }
//   return localStorage.getItem(key)
// }

const getItem = (key) => {
  try {
    return JSON.parse(Cookies.get(key));
  } catch (error) {
    return Cookies.get(key);
  }
};

//Hide Menu
const inArray = (needle, haystack) => {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] == needle) return true;
  }
  return false;
};

function formatNumber(number, prefix = null) {
  // change number format it's number greater than 0
  if (number > 0) {
    const format = parseInt(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (prefix) {
      return `${prefix} ${format}`;
    }
    return format;
  } else {
    return 0;
  }
}

const checkEmailVerivied = (navigate) => {
  if (getItem('access_token')) {
    const user = getItem('user');
    if (!user?.is_email_verified) {
      return navigate('/akun/verifikasi-email');
    }
  }
};

const removeDuplicates = (array) => {
  return array.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

export { getProductVariable, getItem, inArray, formatNumber, checkEmailVerivied, removeDuplicates };

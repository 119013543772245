import React, { useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CheckoutClient from '../container/client/Containers/Checkout';
import HistoryClient from '../container/client/Containers/History';
import HomeClient from '../container/client/Containers/Home';
import PriceClient from '../container/client/Containers/Price';
import '../container/client/index.css';
import ProductDetail from '../container/client/Containers/Product/ProductDetail';
import ProfileClient from '../container/client/Containers/Profile';
import BalanceClient from '../container/client/Containers/Balance';
import TopUpClient from '../container/client/Containers/Balance/TopUp';
import HistoryDepositDetail from '../container/client/Containers/Balance/HistoryDetail';
import HistoryTransactionDetail from '../container/client/Containers/History/HistoryDetail';
import AboutUs from '../container/client/Containers/Profile/Info/AboutUs';
import CustomerService from '../container/client/Containers/Profile/Info/CustomerService';
import LiveChat from '../container/client/Containers/Profile/Info/LiveChat';
import UpdateProfile from '../container/client/Containers/Profile/Setting/UpdateProfile';
import UpdatePassword from '../container/client/Containers/Profile/Setting/UpdatePassword';
import Notification from '../container/client/Containers/Notification';
import VerificationEmail from '../container/client/Containers/Auth/VerificationEmail';
import { checkEmailVerivied } from '../container/client/Utils/helper';
import PrivacyPolicy from '../container/client/Containers/Profile/Info/PrivacyPolicy';

const ClientRoot = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);
};

const ClientFrontendRoutes = React.memo(() => {
  const navigate = useNavigate();
  useEffect(() => {
    checkEmailVerivied(navigate);
  }, []);
  return (
    <Routes>
      <Route index element={<HomeClient />} />
      <Route path="/" element={<HomeClient />} />
      <Route path="/product/:slug" element={<ProductDetail />} />
      <Route path="/price" element={<PriceClient />} />
      <Route path="/history" element={<HistoryClient />} />
      <Route path="/profile" element={<ProfileClient />} />
      <Route path="/saldo" element={<BalanceClient />} />
      <Route path="/topup" element={<TopUpClient />} />
      <Route path="/topup/detail/:reference" element={<HistoryDepositDetail />} />
      <Route path="/transaksi/detail/:reference" element={<HistoryTransactionDetail />} />
      <Route path="/transaksi/:product_type/:product" element={<CheckoutClient />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/akun/verifikasi-email" element={<VerificationEmail />} />

      {/* info */}
      <Route path="/info/tentang-kami" element={<AboutUs />} />
      <Route path="/info/bantuan" element={<CustomerService />} />
      <Route path="/info/kebijakan-privasi" element={<PrivacyPolicy />} />
      <Route path="/cs/live-chat" element={<LiveChat />} />
      <Route path="/setting/update-profile" element={<UpdateProfile />} />
      <Route path="/setting/update-password" element={<UpdatePassword />} />
      <Route path="*" element={<ClientRoot />} />
    </Routes>
  );
});

export default ClientFrontendRoutes;

import { DataService } from '../../config/dataService/dataService';
import actions from './actions';

const {
  loadProductCategoryBegin,
  loadProductCategorySuccess,
  loadProductCategoryErr,
  loadProductProviderBegin,
  loadProductProviderSuccess,
  loadProductProviderErr,
} = actions;

const loadProductCategory = () => {
  return async (dispatch) => {
    dispatch(loadProductCategoryBegin());
    try {
      const response = await DataService.get('/product/category');
      const { data } = response.data;
      dispatch(loadProductCategorySuccess(data));
    } catch (err) {
      const { response } = err;
      dispatch(loadProductCategoryErr(response));
    }
  };
};

const loadProductProvider = () => {
  return async (dispatch) => {
    dispatch(loadProductProviderBegin());
    try {
      const response = await DataService.get('/product/category');
      const { data } = response.data;
      dispatch(loadProductProviderSuccess(data));
    } catch (err) {
      const { response } = err;
      dispatch(loadProductProviderErr(response));
    }
  };
};

export { loadProductCategory, loadProductProvider };

import UilHdd from '@iconscout/react-unicons/icons/uil-hdd';
import UilUpload from '@iconscout/react-unicons/icons/uil-upload';
import { Badge } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { UserActionDropDown } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { DataService } from '../../../config/dataService/dataService';

const NotificationBox = React.memo(() => {
  const [notifications, setNotifications] = React.useState([]);
  const { rtl } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
    };
  });

  function renderThumb({ style }) {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  }

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  function renderView({ style }) {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div style={{ ...style, ...customStyle }} />;
  }

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  useEffect(() => {
    DataService.get('/notification/list').then((res) => {
      const { data } = res.data;
      setNotifications(data);
    });
  }, []);

  const unread = notifications && notifications.filter((item) => item.status === 'unread').length;

  const content = (
    <UserActionDropDown className="ninjadash-top-dropdown">
      <Heading as="h5" className="ninjadash-top-dropdown__title">
        <span className="title-text">Notifications</span>
        <Badge className="badge-success" count={unread} />
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
        renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
      >
        <ul className="ninjadash-top-dropdown__nav notification-list">
          {notifications.map((item, index) => {
            return (
              <li key={index}>
                <Link to="#">
                  <div className="ninjadash-top-dropdown__content notifications">
                    <div className="notification-icon bg-primary">
                      <UilHdd />
                    </div>
                    <div className="notification-content d-flex">
                      <div className="notification-text">
                        <Heading as="h5">{item.title}</Heading>
                        <p>{item.created_at}</p>
                      </div>
                      <div className="notification-status">
                        <Badge dot />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </Scrollbars>
      <a className="btn-seeAll" href="/admin/notification">
        See all incoming activity
      </a>
    </UserActionDropDown>
  );

  return (
    <div className="ninjadash-nav-actions__item ninjadash-nav-actions__notification">
      <Popover placement="bottomLeft" content={content} action="click">
        <Link to="#" className="ninjadash-nav-action-link">
          <ReactSVG src={require('../../../static/img/icon/bell.svg').default} />
        </Link>
      </Popover>
    </div>
  );
});

export default NotificationBox;

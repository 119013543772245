import React, { useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import CardInfo from '../../components/cardInfo';
import { FileTextOutlined } from '@ant-design/icons';
import { DataService } from '../../config/dataService/dataService';

function Dashboard() {
  const PageRoutes = [
    {
      path: 'index',
      breadcrumbName: 'Dashboard',
    },
  ];

  const [dashboard, setDashboard] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getDetail = () => {
    setLoading(true);
    DataService.get(`/dashboard/report`)
      .then((res) => {
        const { data } = res.data;
        setLoading(false);
        setDashboard(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const fields = ['general', 'general_count', 'transaction_count', 'transaction_amount', 'transaction_status'];

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Dashboard" routes={PageRoutes} />
      <Main className="h-screen">
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            {loading ? (
              <Cards headless className={'flex items-center justify-center'}>
                <Spin />
              </Cards>
            ) : (
              fields.map((item) => (
                <Row gutter={25} key={item}>
                  {dashboard[item] &&
                    dashboard[item].map((value) => (
                      <Col xs={12} sm={8} key={value.id}>
                        <CardInfo item={value} />
                      </Col>
                    ))}
                </Row>
              ))
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default Dashboard;

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataService } from '../../../../config/dataService/dataService';
import { ProductItem } from '../../../deposit/deposit';
import Button from '../../Components/Button';

const HistoryTransactionDetail = () => {
  const { reference } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    data: null,
    loading: false,
  });

  const getDetail = () => {
    setState((currentState) => ({ ...currentState, loading: true }));
    DataService.get(`/transaction/product/detail/${reference}`)
      .then((res) => {
        const { data } = res.data;
        setState((currentState) => ({ ...currentState, loading: false, data }));
      })
      .catch((err) => {
        setState((currentState) => ({ ...currentState, loading: false }));
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const { data, loading } = state;
  const detailItem = data?.response_data ? JSON.parse(data?.response_data) : {};
  return (
    <div className="bg-[#ecf0f1]">
      <div className=" bg-[#f5f6fa] shadow-horizontal max-w-lg min-h-screen mx-auto my-0">
        {/* header */}
        <nav className="navbar">
          <Layout>
            <Layout.Header className="nav-header">
              <div className="flex justify-center items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-xl absolute left-4 cursor-pointer"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <span className="capitalize text-md font-bold">Detail Transaksi</span>
              </div>
            </Layout.Header>
          </Layout>
        </nav>

        {/* content */}
        <div className={`mx-4 bg-white  rounded-lg mt-2`} key={data?.id}>
          <div className="flex justify-between items-center px-4 py-2">
            <div>
              <p className="mb-0 text-sm font-bold">{data?.transaction_id}</p>
              <p className="mb-0 text-xs">{data?.transaction_date}</p>
            </div>
            <Tag color={data?.status_color} className="mr-0">
              {data?.status_name}
            </Tag>
          </div>
          <div className="px-4 py-2">
            <ProductItem label="Jenis Produk" value={data?.product?.product_category} />
            <ProductItem label="Provider" value={data?.product?.product_brand} />
            <ProductItem label="Produk" value={data?.product?.product_original_name} />
            <ProductItem label={detailItem?.label} value={data?.customer_no} />
            <ProductItem label="Nominal" value={data?.price} />
            <ProductItem label="Pembayaran" value={data?.payment_name} />
            <ProductItem label="Tanggal Pembelian" value={data?.transaction_date} />
            <ProductItem
              label="Status Pembayaran"
              value={
                <Tag color={data?.status_product_color} style={{ color: '#fff' }}>
                  {data?.status_product_name}
                </Tag>
              }
            />
            <ProductItem
              label="Status Pengisian"
              value={
                <Tag color={data?.status_color} style={{ color: '#fff' }}>
                  {data?.status_name}
                </Tag>
              }
            />
            {data?.serial_number && <ProductItem label="SN" value={data?.serial_number} />}
          </div>
        </div>

        {/* footer */}
        <div className="max-w-lg mx-auto fixed inset-x-0 bottom-0 border-t  bg-white ">
          <div className=" inset-x-0 bottom-0 border-t">
            <div className="mx-4 py-2 bg-white rounded">
              {!data?.payment && data?.status < 1 && (
                <Button onClick={() => window.open(data?.paymentUrl, '_blank')} title="Bayar Sekarang" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryTransactionDetail;

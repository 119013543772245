import { MoneyCollectFilled } from '@ant-design/icons';
import { Col, Form, Input, InputNumber, message, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '../../../components/modals/antd-modals';
import { DataService } from '../../../config/dataService/dataService';
import { formatNumber } from '../../../utility/utility';

const ModalForm = ({ refetch, initialValues = {}, url }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    setLoading(true);

    DataService.post(url, { ...value, user_id: initialValues?.user_id })
      .then((res) => {
        setLoading(false);
        form.resetFields();
        setIsModalOpen(false);
        refetch();
        message.success(res.data.message);
      })
      .catch((err) => {
        const { data } = err.response;
        setLoading(false);
        message.error(data?.message);
      });
  };

  return (
    <>
      <Link
        to="#"
        onClick={(e) => {
          e?.stopPropagation();
          showModal();
        }}
      >
        <span className="flex flex-row items-center">
          <MoneyCollectFilled />
          <span>Update Balance</span>
        </span>
      </Link>
      <Modal
        title={`Saldo ${formatNumber(initialValues?.balance, 'Rp ') || 0}`}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
        width={1000}
        confirmLoading={loading}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={{ ...initialValues }}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={12}>
              <Form.Item label="Amount" name="balance">
                <Input className="w-full py-2" type="number" size="small" />
              </Form.Item>
              <Form.Item label="Type" name="type">
                <Select size="medium">
                  <Select.Option value="CR">Credit</Select.Option>
                  <Select.Option value="DB">Debit</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Description" name="description">
                <Input size="small" className=" py-2" />
              </Form.Item>
              <Form.Item label="Password Verivication" name="password">
                <Input.Password size="small" />
              </Form.Item>
            </Col>
            <Col xs={24}></Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;
